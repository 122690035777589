import React from "react";
import { ThemeProvider } from "../../Utils/themeProvider";
import { Typography } from "@material-ui/core";
import "../stylesheet.css";
import "../font.css";

export default function productPage() {
  return (
    <ThemeProvider>
      <Typography>Pagina Prodotti</Typography>
    </ThemeProvider>
  );
}
