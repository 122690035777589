import React from "react";
import "./rotateButton.css";

const RotatingTextFooter = () => {
  return (
    <svg
      className="rotating"
      fill="none"
      width="577"
      height="577"
      viewBox="0 0 577 577"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.71885 194.505L14.4352 182.245C15.4036 180.168 16.6563 178.503 18.1932 177.249C19.7157 175.956 21.3786 175.171 23.1819 174.895C24.9583 174.607 26.7232 174.872 28.4765 175.689C30.2299 176.507 31.5806 177.695 32.5287 179.253C33.4498 180.799 33.9179 182.577 33.933 184.588C33.9337 186.558 33.4498 188.582 32.4813 190.659L28.9723 198.185L39.5734 203.128L37.3661 207.862L8.71885 194.505ZM28.303 189.056C29.1457 187.249 29.3789 185.551 29.0026 183.964C28.6118 182.337 27.6882 181.183 26.2315 180.504C24.7479 179.812 23.2771 179.833 21.8191 180.565C20.3342 181.285 19.1641 182.562 18.3089 184.396L14.9319 191.639L24.9261 196.299L28.303 189.056Z"
        fill="black"
      />
      <path
        d="M56.7621 171.897C55.8847 173.314 54.8483 174.423 53.6529 175.223C52.4574 176.023 51.221 176.465 49.9437 176.549C48.6663 176.634 47.4582 176.323 46.3195 175.618C44.6746 174.6 43.7136 173.042 43.4366 170.946C43.1752 168.824 43.7465 166.289 45.1505 163.343L47.4066 158.596C47.6693 158.059 47.7555 157.552 47.6651 157.076C47.5748 156.6 47.3019 156.221 46.8464 155.939C45.986 155.407 44.9906 155.403 43.8602 155.928C42.7298 156.454 41.726 157.425 40.8486 158.842C40.2376 159.829 39.8761 160.865 39.7639 161.951C39.6422 162.996 39.7488 163.954 40.0838 164.827C40.3934 165.684 40.8537 166.354 41.4647 166.837L37.862 169.857C36.8522 169.057 36.1376 167.95 35.7183 166.535C35.2737 165.104 35.1961 163.534 35.4854 161.822C35.765 160.07 36.4297 158.346 37.4794 156.651C38.5447 154.93 39.7884 153.6 41.2104 152.66C42.6481 151.695 44.1304 151.195 45.6572 151.16C47.1743 151.084 48.6415 151.485 50.0586 152.362L62.9265 160.329L60.7645 163.821L57.5651 162.628L57.5416 162.666C58.2164 164.064 58.4941 165.566 58.3747 167.172C58.271 168.753 57.7334 170.328 56.7621 171.897ZM54.0653 168.705C55.1307 166.984 55.5146 165.261 55.217 163.537C54.9194 161.812 53.9988 160.472 52.4552 159.517L51.2405 158.765L48.5343 164.493C47.6617 166.298 47.2531 167.778 47.3085 168.932C47.3796 170.061 47.8073 170.869 48.5918 171.354C49.4269 171.871 50.3482 171.882 51.3556 171.385C52.3787 170.864 53.282 169.97 54.0653 168.705Z"
        fill="black"
      />
      <path
        d="M56.662 129.291C57.8838 127.715 59.3834 126.825 61.1608 126.621C62.9564 126.394 64.8327 126.888 66.7897 128.104L65.2689 132.179C64.0905 131.416 62.9649 131.07 61.8921 131.142C60.8194 131.215 59.9456 131.686 59.2709 132.556C58.2496 133.873 57.9738 135.298 58.4434 136.829C58.8895 138.343 60.0299 139.81 61.8646 141.233L71.4261 148.646L68.4445 152.492L50.5211 138.596L52.9556 135.456L56.1259 137.123L56.1532 137.087C55.4542 135.754 55.1454 134.404 55.2268 133.036C55.3265 131.645 55.8049 130.396 56.662 129.291Z"
        fill="black"
      />
      <path
        d="M61.0776 109.346L64.348 105.742L88.7457 127.885L85.4753 131.488L61.0776 109.346Z"
        fill="black"
      />
      <path
        d="M101.647 115.95C100.429 117.087 99.1378 117.884 97.7742 118.343C96.4106 118.801 95.1015 118.903 93.8468 118.649C92.5921 118.395 91.5079 117.778 90.5942 116.799C89.2744 115.385 88.756 113.629 89.0391 111.533C89.344 109.417 90.5606 107.122 92.6888 104.647L96.1119 100.659C96.5065 100.21 96.7227 99.7435 96.7605 99.2604C96.7983 98.7774 96.6344 98.3401 96.2689 97.9484C95.5786 97.2085 94.619 96.9436 93.3904 97.1538C92.1618 97.364 90.9381 98.0376 89.7195 99.1747C88.8709 99.9666 88.2499 100.872 87.8567 101.89C87.4649 102.866 87.3161 103.819 87.4103 104.749C87.4841 105.657 87.7524 106.424 88.215 107.051L83.9458 109.02C83.1815 107.982 82.7828 106.726 82.7496 105.251C82.6961 103.754 83.0336 102.218 83.7619 100.643C84.4918 99.0254 85.5857 97.5365 87.0436 96.1761C88.5234 94.7953 90.0727 93.8382 91.6916 93.3046C93.3322 92.7507 94.8937 92.6573 96.3762 93.0245C97.86 93.3496 99.1705 94.1214 100.308 95.3401L110.633 106.405L107.63 109.208L104.856 107.216L104.823 107.247C105.107 108.773 104.981 110.295 104.444 111.814C103.929 113.312 102.997 114.691 101.647 115.95ZM99.8834 112.161C101.363 110.781 102.186 109.219 102.351 107.477C102.517 105.735 101.98 104.2 100.742 102.873L99.7672 101.828L95.6521 106.645C94.3362 108.158 93.5535 109.478 93.3039 110.607C93.076 111.715 93.2768 112.607 93.9062 113.281C94.5763 113.999 95.4625 114.251 96.565 114.036C97.6892 113.802 98.7953 113.177 99.8834 112.161Z"
        fill="black"
      />
      <path
        d="M127.542 94.2448C125.677 95.6279 123.694 96.451 121.591 96.7138C119.488 96.9766 117.469 96.6774 115.533 95.816C113.603 94.913 111.938 93.5174 110.537 91.629C109.136 89.7407 108.28 87.7629 107.969 85.6957C107.659 83.6285 107.886 81.6623 108.652 79.7972C109.424 77.8904 110.694 76.2809 112.463 74.9687C114.567 73.4082 116.842 72.6284 119.288 72.6292C121.759 72.6124 123.9 73.3766 125.714 74.9219L122.695 78.4955C121.533 77.5787 120.241 77.1106 118.818 77.0913C117.42 77.0542 116.147 77.4613 114.999 78.3124C113.995 79.0572 113.291 80.006 112.886 81.1587C112.481 82.3114 112.403 83.5551 112.652 84.8898C112.901 86.2245 113.487 87.5133 114.409 88.7563C115.349 90.0231 116.433 90.9604 117.662 91.568C118.915 92.1579 120.188 92.3997 121.48 92.2934C122.779 92.1454 123.989 91.6547 125.113 90.8212C126.523 89.775 127.435 88.4315 127.848 86.7907C128.262 85.1499 128.059 83.5774 127.239 82.073L131.411 79.9786C132.248 81.5069 132.641 83.1608 132.59 84.9404C132.563 86.7022 132.108 88.4112 131.224 90.0674C130.347 91.6819 129.119 93.0744 127.542 94.2448Z"
        fill="black"
      />
      <path
        d="M131.727 58.2747C130.917 58.7753 130.11 58.9415 129.306 58.7733C128.487 58.5797 127.827 58.0778 127.326 57.2676C126.842 56.4827 126.704 55.6932 126.913 54.8993C127.106 54.08 127.608 53.4201 128.418 52.9195C129.229 52.4189 130.043 52.2654 130.863 52.4589C131.666 52.6271 132.311 53.1037 132.796 53.8886C133.296 54.6989 133.45 55.5136 133.256 56.3329C133.047 57.1269 132.537 57.7741 131.727 58.2747ZM131.642 62.893L135.781 60.3354L147.702 79.6294L143.562 82.187L131.642 62.893Z"
        fill="black"
      />
      <path
        d="M181.044 63.3643C179.1 64.1914 177.143 64.4577 175.174 64.1631C173.233 63.8568 171.463 63.0411 169.864 61.7158C168.254 60.3631 166.994 58.6186 166.086 56.4823C165.177 54.346 164.8 52.2424 164.954 50.1716C165.097 48.0733 165.731 46.2187 166.857 44.6078C167.983 42.9968 169.518 41.7778 171.463 40.9507C173.051 40.2751 174.621 40.0117 176.172 40.1605C177.751 40.2977 179.252 40.8236 180.676 41.7381L180.717 41.7206L175.335 29.0671L179.772 27.18L192.668 57.4989L188.888 59.1065L186.915 56.0645L186.874 56.082C186.557 57.7694 185.906 59.2429 184.921 60.5025C183.925 61.7348 182.633 62.6887 181.044 63.3643ZM180.312 59.0185C181.599 58.471 182.616 57.6668 183.361 56.6058C184.122 55.5059 184.557 54.2858 184.667 52.9456C184.764 51.578 184.527 50.2233 183.957 48.8812C183.386 47.5392 182.58 46.4427 181.539 45.5918C180.486 44.7135 179.305 44.1807 177.997 43.9935C176.704 43.7672 175.413 43.9278 174.126 44.4753C172.866 45.0111 171.856 45.8291 171.095 46.9291C170.349 47.99 169.928 49.2043 169.83 50.5718C169.721 51.912 169.952 53.2531 170.523 54.5951C171.105 55.9645 171.923 57.0884 172.975 57.9667C174.044 58.8059 175.219 59.325 176.5 59.5239C177.781 59.7229 179.052 59.5544 180.312 59.0185Z"
        fill="black"
      />
      <path
        d="M213.754 51.692C211.382 52.38 209.103 52.483 206.918 52.001C204.761 51.5107 202.893 50.5185 201.313 49.0244C199.725 47.5016 198.595 45.5825 197.924 43.2671C197.286 41.0661 197.2 38.9528 197.666 36.9272C198.132 34.9017 199.082 33.1543 200.515 31.6851C201.969 30.1789 203.782 29.1109 205.954 28.4809C208.098 27.8592 210.14 27.7939 212.08 28.285C214.019 28.7762 215.681 29.7508 217.065 31.2089C218.477 32.6588 219.502 34.4842 220.14 36.6853C220.323 37.3142 220.454 37.9269 220.534 38.5235L203.211 43.5468C204.032 45.416 205.317 46.7478 207.066 47.5422C208.806 48.308 210.691 48.3966 212.721 47.8081C214.264 47.3605 215.556 46.6297 216.594 45.6158C217.633 44.6019 218.284 43.4832 218.549 42.2598L222.532 43.0106C222.298 44.8758 221.377 46.6149 219.769 48.228C218.189 49.8327 216.184 50.9874 213.754 51.692ZM214.822 36.461C214.067 34.6038 213.001 33.3325 211.625 32.647C210.277 31.9532 208.745 31.855 207.03 32.3523C205.344 32.8414 204.072 33.799 203.215 35.2251C202.35 36.6226 202.017 38.2531 202.216 40.1165L214.822 36.461Z"
        fill="black"
      />
      <path
        d="M226.55 13.6765L231.323 12.731L237.725 45.0505L232.952 45.996L226.55 13.6765Z"
        fill="black"
      />
      <path
        d="M275.322 41.4676C272.975 41.5976 271.034 41.0046 269.5 39.6886C267.967 38.3725 267.138 36.6001 267.015 34.3713L266.338 22.1575L261.569 22.4218L261.359 18.6328L266.128 18.3686L265.765 11.8159L270.565 10.4769L270.987 18.0994L280.839 17.5537L281.048 21.3426L271.197 21.8884L271.837 33.4335C271.904 34.6519 272.302 35.6434 273.03 36.4079C273.786 37.141 274.714 37.4771 275.813 37.4162C276.913 37.3553 277.888 36.9287 278.738 36.1364C279.588 35.344 280.125 34.271 280.348 32.9173L283.367 34.941C283.025 36.8379 282.097 38.3797 280.582 39.5665C279.067 40.7236 277.313 41.3573 275.322 41.4676Z"
        fill="black"
      />
      <path
        d="M299.563 41.3101C297.036 41.1795 295.099 40.3193 293.75 38.7297C292.404 37.1103 291.8 34.9482 291.94 32.2434L292.707 17.3967L297.523 17.6457L296.803 31.5561C296.717 33.2206 297.109 34.582 297.978 35.6402C298.849 36.6687 300.027 37.2213 301.513 37.2982C303.416 37.3965 305.023 36.8538 306.336 35.6699C307.651 34.4564 308.359 32.8539 308.462 30.8624L309.115 18.245L313.974 18.4962L312.803 41.1452L308.568 40.9263L308.112 37.6393L308.067 37.637C305.905 40.267 303.07 41.4914 299.563 41.3101Z"
        fill="black"
      />
      <path
        d="M333.886 44.9658C331.451 44.5486 329.395 43.6829 327.717 42.3688C326.07 41.0598 324.903 39.4104 324.217 37.4207C323.531 35.431 323.395 33.2334 323.807 30.8279C324.219 28.4224 325.08 26.3957 326.389 24.7479C327.698 23.1 329.347 21.9332 331.337 21.2475C333.356 20.5668 335.583 20.435 338.018 20.8523C340.423 21.2645 342.45 22.1251 344.098 23.4342C345.775 24.7483 346.956 26.4002 347.642 28.3899C348.357 30.3846 348.509 32.5847 348.096 34.9902C347.684 37.3957 346.809 39.4199 345.47 41.0627C344.161 42.7106 342.497 43.8749 340.478 44.5556C338.489 45.2413 336.291 45.3781 333.886 44.9658ZM334.594 40.8296C336.824 41.2116 338.736 40.7996 340.332 39.5934C341.928 38.3872 342.932 36.5814 343.344 34.1759C343.756 31.7704 343.408 29.748 342.3 28.1086C341.226 26.445 339.56 25.4196 337.301 25.0326C335.043 24.6455 333.115 25.055 331.52 26.2612C329.958 27.4431 328.971 29.2368 328.559 31.6423C328.147 34.0478 328.492 36.0848 329.595 37.7535C330.699 39.4222 332.365 40.4475 334.594 40.8296Z"
        fill="black"
      />
      <path
        d="M379.494 31.7175L383.346 33.142L382.694 36.7086L382.735 36.7241C384.152 35.7248 385.632 35.1136 387.174 34.8906C388.754 34.65 390.354 34.829 391.973 35.4277C393.955 36.1606 395.547 37.3046 396.749 38.8597C397.979 40.4251 398.715 42.2524 398.958 44.3414C399.211 46.4024 398.936 48.5217 398.13 50.6991C397.325 52.8765 396.151 54.6794 394.607 56.1077C393.074 57.5081 391.345 58.4078 389.42 58.8068C387.496 59.2058 385.543 59.0388 383.561 58.3059C381.942 57.7072 380.619 56.8215 379.593 55.6488C378.567 54.476 377.85 53.0685 377.442 51.4262L377.4 51.4107L372.94 63.4701L368.376 61.7824L379.494 31.7175ZM384.042 53.9145C385.326 54.3893 386.603 54.4967 387.874 54.2366C389.182 53.9589 390.336 53.3701 391.335 52.4702C392.345 51.5425 393.102 50.3947 393.608 49.0268C394.124 47.631 394.282 46.2613 394.08 44.9176C393.917 43.5563 393.438 42.3636 392.643 41.3396C391.847 40.3157 390.808 39.5662 389.523 39.0914C388.211 38.6062 386.915 38.5077 385.634 38.7957C384.364 39.0558 383.224 39.6498 382.215 40.5775C381.216 41.4774 380.463 42.6112 379.957 43.9791C379.452 45.3469 379.28 46.7115 379.443 48.0728C379.617 49.4062 380.096 50.5988 380.881 51.6508C381.676 52.6747 382.73 53.4293 384.042 53.9145Z"
        fill="black"
      />
      <path
        d="M422.884 49.0447C424.667 49.9386 425.83 51.2378 426.373 52.9422C426.944 54.6599 426.821 56.5964 426.007 58.7517L421.715 58.0477C422.236 56.7439 422.357 55.5727 422.079 54.5342C421.801 53.4956 421.169 52.7295 420.185 52.2358C418.695 51.4886 417.244 51.4935 415.832 52.2505C414.434 52.9808 413.214 54.3836 412.174 56.4587L406.75 67.2734L402.4 65.0918L412.567 44.8193L416.119 46.6006L415.096 50.0334L415.136 50.0534C416.309 49.1098 417.574 48.5457 418.932 48.361C420.316 48.1897 421.634 48.4175 422.884 49.0447Z"
        fill="black"
      />
      <path
        d="M435.587 84.5358C433.515 83.1912 431.968 81.5842 430.946 79.7147C429.949 77.8614 429.528 75.8853 429.683 73.7865C429.839 71.6877 430.581 69.6147 431.909 67.5673C433.237 65.5199 434.828 63.9977 436.682 63.0005C438.535 62.0033 440.511 61.5824 442.61 61.7379C444.733 61.9096 446.832 62.6677 448.904 64.0123C450.951 65.3407 452.473 66.9316 453.471 68.7848C454.493 70.6543 454.926 72.6385 454.771 74.7373C454.64 76.8523 453.911 78.9335 452.582 80.9808C451.254 83.0282 449.651 84.5424 447.772 85.5234C445.919 86.5206 443.93 86.9333 441.807 86.7616C439.708 86.6061 437.635 85.8642 435.587 84.5358ZM437.872 81.0154C439.769 82.2465 441.689 82.623 443.631 82.1448C445.574 81.6666 447.209 80.4038 448.538 78.3564C449.866 76.3091 450.345 74.3136 449.974 72.3699C449.644 70.4175 448.518 68.8176 446.595 67.5702C444.673 66.3228 442.74 65.9382 440.798 66.4165C438.897 66.8859 437.282 68.1443 435.954 70.1917C434.625 72.239 434.139 74.247 434.493 76.2156C434.848 78.1843 435.974 79.7842 437.872 81.0154Z"
        fill="black"
      />
      <path
        d="M454.792 110.171C452.83 108.527 451.318 106.601 450.257 104.392C449.176 102.205 448.73 100.143 448.918 98.2034L453.293 97.5001C453.075 99.1039 453.337 100.76 454.08 102.469C454.823 104.179 455.89 105.616 457.281 106.782C459.221 108.406 461.261 109.145 463.403 108.998C465.568 108.871 467.415 107.894 468.944 106.069L470.177 104.597L470.143 104.568C468.69 104.866 467.175 104.82 465.599 104.431C464.022 104.042 462.675 103.379 461.557 102.443C459.914 101.067 458.771 99.4881 458.128 97.7069C457.504 95.9028 457.402 94.0314 457.822 92.0928C458.265 90.1733 459.223 88.3352 460.694 86.5784C462.204 84.776 463.875 83.477 465.706 82.6815C467.537 81.8859 469.396 81.6373 471.281 81.9355C473.167 82.2338 474.919 83.0614 476.539 84.4184C477.726 85.4123 478.629 86.674 479.25 88.2037C479.894 89.7526 480.157 91.2928 480.039 92.8244L480.073 92.8531L482.76 90.6192L485.942 93.2854L472.612 109.199C471.063 111.047 469.275 112.325 467.246 113.032C465.239 113.759 463.158 113.879 461.003 113.394C458.847 112.908 456.777 111.834 454.792 110.171ZM465.044 99.9482C466.117 100.846 467.274 101.389 468.516 101.575C469.757 101.761 470.97 101.612 472.152 101.127C473.335 100.642 474.375 99.8635 475.273 98.7912C476.21 97.6733 476.823 96.4783 477.112 95.2062C477.42 93.9114 477.374 92.6689 476.973 91.4789C476.591 90.2661 475.864 89.2106 474.792 88.3123C473.172 86.9553 471.401 86.404 469.48 86.6582C467.559 86.9123 465.862 87.9178 464.39 89.6746C462.957 91.3858 462.303 93.1866 462.427 95.077C462.552 96.9675 463.424 98.5912 465.044 99.9482Z"
        fill="black"
      />
      <path
        d="M486.435 127.079C484.745 125.278 483.619 123.294 483.056 121.129C482.513 118.985 482.549 116.87 483.163 114.784C483.799 112.677 484.995 110.799 486.753 109.15C488.425 107.581 490.268 106.545 492.285 106.041C494.301 105.537 496.289 105.589 498.249 106.199C500.251 106.81 502.026 107.941 503.574 109.59C505.101 111.218 506.086 113.008 506.53 114.959C506.973 116.91 506.859 118.833 506.188 120.728C505.538 122.644 504.377 124.387 502.705 125.955C502.228 126.403 501.742 126.798 501.246 127.14L488.905 113.987C487.612 115.567 487.009 117.317 487.096 119.236C487.204 121.134 487.981 122.854 489.427 124.395C490.526 125.567 491.764 126.386 493.139 126.85C494.513 127.315 495.806 127.388 497.016 127.069L498.156 130.958C496.388 131.597 494.42 131.566 492.253 130.865C490.106 130.186 488.166 128.924 486.435 127.079ZM500.491 121.115C501.802 119.599 502.451 118.072 502.437 116.534C502.443 115.018 501.835 113.609 500.613 112.307C499.412 111.026 497.981 110.328 496.321 110.212C494.683 110.076 493.079 110.52 491.51 111.543L500.491 121.115Z"
        fill="black"
      />
      <path
        d="M506.011 150.557C504.598 148.678 504.012 146.735 504.254 144.729C504.496 142.722 505.508 141.048 507.293 139.707L517.069 132.355L514.199 128.537L517.232 126.257L520.102 130.075L525.348 126.13L529.129 129.376L523.027 133.964L528.957 141.85L525.924 144.13L519.994 136.245L510.752 143.194C509.777 143.927 509.174 144.809 508.943 145.839C508.754 146.875 508.99 147.833 509.652 148.713C510.314 149.593 511.21 150.167 512.342 150.433C513.473 150.7 514.663 150.549 515.913 149.982L515.908 153.616C514.141 154.386 512.344 154.472 510.516 153.873C508.711 153.256 507.21 152.151 506.011 150.557Z"
        fill="black"
      />
      <path
        d="M520.979 172.981C519.76 170.97 519.37 168.979 519.81 167.007C520.25 165.034 521.424 163.469 523.333 162.312L533.792 155.969L531.315 151.884L534.56 149.917L537.037 154.001L542.649 150.598L546.088 154.204L539.56 158.162L544.676 166.599L541.432 168.566L536.316 160.13L526.429 166.126C525.385 166.758 524.697 167.576 524.365 168.578C524.074 169.59 524.214 170.567 524.785 171.508C525.356 172.45 526.191 173.109 527.29 173.487C528.389 173.865 529.589 173.833 530.888 173.394L530.522 177.009C528.688 177.6 526.891 177.506 525.131 176.728C523.397 175.935 522.013 174.686 520.979 172.981Z"
        fill="black"
      />
      <path
        d="M533.996 197.55C532.956 195.309 532.5 193.125 532.629 190.998C532.77 188.898 533.465 187.001 534.713 185.306C535.961 183.612 537.692 182.251 539.905 181.224C542.119 180.196 544.276 179.753 546.376 179.895C548.476 180.036 550.373 180.73 552.068 181.978C553.775 183.253 555.148 185.011 556.188 187.252C557.215 189.466 557.658 191.623 557.517 193.722C557.388 195.849 556.7 197.76 555.452 199.455C554.217 201.176 552.492 202.551 550.278 203.578C548.064 204.605 545.901 205.035 543.789 204.866C541.689 204.725 539.786 204.017 538.078 202.742C536.384 201.494 535.023 199.763 533.996 197.55ZM537.802 195.783C538.754 197.835 540.18 199.175 542.079 199.803C543.978 200.431 546.035 200.232 548.249 199.204C550.462 198.177 551.929 196.742 552.649 194.899C553.408 193.07 553.305 191.116 552.341 189.037C551.376 186.958 549.944 185.605 548.045 184.977C546.185 184.363 544.149 184.57 541.935 185.597C539.721 186.625 538.241 188.066 537.494 189.922C536.748 191.778 536.85 193.731 537.802 195.783Z"
        fill="black"
      />
    </svg>
  );
};

export default RotatingTextFooter;
