import { graphql, useStaticQuery } from "gatsby";

const useFetchServices = () => {
  const items = useStaticQuery(
    graphql`
      {
        allContentfulServiziAdv {
          edges {
            node {
              descrizione {
                raw
              }
              titolo
            }
          }
        }
      }
    `
  );

  return {
    services: items.allContentfulServiziAdv.edges,
  };
};

export default useFetchServices;
