import React from "react";

const MenuIcon = (props) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M4 13.5C4.55228 13.5 5 13.0523 5 12.5C5 11.9477 4.55228 11.5 4 11.5C3.44772 11.5 3 11.9477 3 12.5C3 13.0523 3.44772 13.5 4 13.5Z"
        fill="black"
      />
      <path
        d="M20.06 11.5H7.94C7.42085 11.5 7 11.9209 7 12.44V12.56C7 13.0791 7.42085 13.5 7.94 13.5H20.06C20.5791 13.5 21 13.0791 21 12.56V12.44C21 11.9209 20.5791 11.5 20.06 11.5Z"
        fill="black"
      />
      <path
        d="M20.06 16.5H3.94C3.42085 16.5 3 16.9209 3 17.44V17.56C3 18.0791 3.42085 18.5 3.94 18.5H20.06C20.5791 18.5 21 18.0791 21 17.56V17.44C21 16.9209 20.5791 16.5 20.06 16.5Z"
        fill="black"
      />
      <path
        d="M20.06 6.5H3.94C3.42085 6.5 3 6.92085 3 7.44V7.56C3 8.07915 3.42085 8.5 3.94 8.5H20.06C20.5791 8.5 21 8.07915 21 7.56V7.44C21 6.92085 20.5791 6.5 20.06 6.5Z"
        fill="black"
      />
    </svg>
  );
};

export default MenuIcon;
