import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import WorksLayout from "../../Components/WorksLayout";
import SinglePageBlog from "../../Components/SinglePageBlog";
import PageBlogs from "../../Components/PageBlogs";
import { createLink } from "../../../Utils/costant";

const blog = (props) => {
  const { pageContext } = props;
  const categoryLink = pageContext?.categoria
    ? createLink(pageContext.categoria)
    : null;

  if (typeof document === `undefined`) return null;

  return (
    <BrowserRouter>
      <WorksLayout>
        <Routes>
          <Route path="/blog" element={<PageBlogs />} />
          <Route
            path={`/blog/${categoryLink}`}
            element={<PageBlogs categoria={categoryLink} />}
          />
          <Route
            path={`/blog/${pageContext?.blog?.url}`}
            element={<SinglePageBlog pageSelected={pageContext.blog} />}
          />
        </Routes>
      </WorksLayout>
    </BrowserRouter>
  );
};

export default blog;
