import { graphql, useStaticQuery } from "gatsby";

const useFetchWorkItems = () => {
  const items = useStaticQuery(
    graphql`
      {
        allContentfulAdvProgetti {
          edges {
            node {
              id
              order
              urlPath
              titoloProgetto
              servizi
              urlvideo
              urlVideo2
              coverImage {
                file {
                  url
                  fileName
                }
              }
              logo {
                file {
                  fileName
                  url
                }
              }
              descrizioneCorta {
                raw
              }
              descrizioneLunga {
                raw
              }
              gallery {
                file {
                  contentType
                  fileName
                  url
                }
              }
              websiteobj {
                nome
                url
                id
              }
            }
          }
        }
      }
    `
  );
  return {
    works: items.allContentfulAdvProgetti.edges as IWork[],
  };
};

export default useFetchWorkItems;

export interface IWork {
  node: IWorkNode;
}

export interface IWorkNode {
  id: string;
  order: number;
  urlPath: string;
  website: string;
  titoloProgetto: string;
  servizi: string[];
  coverImage: FileType;
  logo: FileType;
  descrizioneCorta: DescrizioneType;
  descrizioneLunga: DescrizioneType;
  gallery: FileType[];
  urlvideo: string;
  urlVideo2: string;
  websiteobj: Website;
}

type FileType = {
  file: {
    url: string;
    fileName: string;
    contentType?: string;
  };
};

type DescrizioneType = {
  raw: string;
};

type Website = {
  nome: string;
  url: string;
  id: string;
};
