import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { alpha, TextField } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CustomText from "../CustomText/CustomText";
import axios from "axios";
import "./checkbox.css";

const useStyles = makeStyles((theme: Theme) => ({
  servicesRoot: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    margin: "60px 0px",
    [theme.breakpoints.down("md")]: {
      margin: "48px 0px",
    },
  },
  servicesBack: {
    maxWidth: 1320,
    width: "100%",
    color: theme.colors.red,
    opacity: 0.1,
    fontWeight: 900,
    fontSize: "clamp(48px, 5.813vw, 93px)",
  },
  servicesFront: {
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tnxBack: {
    maxWidth: 1320,
    width: "100%",
    color: theme.colors.red,
    opacity: 0.1,
    fontWeight: 900,
    fontSize: "clamp(40px, 3.939vw, 52px)",
  },
  form: {
    display: "grid",
    padding: "50px",
    maxWidth: 715,
    width: "100%",
    margin: "0 auto",
  },
  field: {
    paddingBottom: 16,
    "& label.Mui-focused": {
      color: `${theme.colors.black}`,
    },
  },
  button: {
    padding: 16,
    color: theme.colors.white,
    background: theme.colors.red,
    fontWeight: "bold",
    fontFamily: "Agrandir-Wide",
    textTransform: "uppercase",
    border: "none",
    marginTop: 16,
    borderRadius: 0,
    "&:disabled": {
      background: alpha(theme.colors.red, 0.3),
    },
  },
  checkboxText: {
    fontSize: 16,
  },
}));

const Form = () => {
  const classes = useStyles();
  const [sent, setSent] = useState(false);
  const { register, handleSubmit, control, formState, reset } = useForm<any>({
    defaultValues: {
      nome: "",
      ragione: "",
      email: "",
      numero: "",
      descrizione: "",
      consenso: false,
    },
  });
  const { errors, isSubmitSuccessful, dirtyFields } = formState;

  const onSubmit = (data) => sendEmail(data);

  const sendEmail = (data) => {
    axios({
      method: "post",
      url: `https://ad.verteego.it/form/modulo-form-proc.php`,
      headers: { "content-type": "application/json" },
      data,
    })
      .then((result: any) => {
        setSent(result?.sent);
      })
      .catch((error) => console.log("error", error));
  };

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        nome: "",
        ragione: "",
        email: "",
        numero: "",
        descrizione: "",
        consenso: false,
      });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <>
      <div className={classes.servicesRoot}>
        {!sent ? (
          <>
            <CustomText className={classes.servicesBack}>CONTACT US</CustomText>
            <CustomText
              type="titoloH2Sezioni"
              className={classes.servicesFront}
            >
              CONTACT US
            </CustomText>
          </>
        ) : (
          <div style={{ padding: "40px 16px" }}>
            <CustomText className={classes.tnxBack}>
              Grazie per averci contattato
            </CustomText>
            <CustomText type="titoloH4" className={classes.servicesFront}>
              Grazie per averci contattato
            </CustomText>
          </div>
        )}
      </div>
      {!sent && (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Controller
            name="nome"
            control={control}
            {...register("nome", {
              required: "questo campo è obbligatorio",
            })}
            render={({ field, formState }) => (
              <TextField
                required
                {...formState}
                {...field}
                label="Nome"
                placeholder="Mario Rossi"
                helperText={errors?.nome?.message}
                className={classes.field}
                error={formState.errors.nome}
              />
            )}
          />
          <Controller
            control={control}
            name="ragione"
            {...register("ragione", { maxLength: 100 })}
            render={({ field, formState }) => (
              <TextField
                {...field}
                {...formState}
                label="Ragione sociale"
                type="text"
                placeholder="IT123456789"
                className={classes.field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            {...register("email", {
              required: "questo campo è obbligatorio",
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: "Email non valida",
              },
            })}
            render={({ field, formState }) => (
              <TextField
                required
                {...field}
                {...formState}
                label="Email"
                type="email"
                placeholder="esempio@mail.com"
                helperText={errors?.email?.message}
                className={classes.field}
                error={formState.errors.email}
              />
            )}
          />
          <Controller
            name="numero"
            control={control}
            {...register("numero", {
              required: "questo campo è obbligatorio",
              pattern: {
                value: /^[0-9+-]+$/,
                message: "Inserisci un numero di telefono valido",
              },
            })}
            render={({ field, formState }) => (
              <TextField
                required
                {...field}
                {...formState}
                type="tel"
                label="Numero di telefono"
                placeholder="3331234567"
                helperText={errors?.numero?.message}
                className={classes.field}
                error={errors.numero}
              />
            )}
          />
          <Controller
            name="descrizione"
            control={control}
            {...register("descrizione", { maxLength: 255 })}
            render={({ field, formState }) => (
              <TextField
                multiline
                minRows={4}
                {...field}
                {...formState}
                type="text"
                label="Descrizione"
                helperText={errors?.descrizione?.message}
                className={classes.field}
              />
            )}
          />
          <div style={{ display: "flex", marginTop: 8 }}>
            <input
              name="consenso"
              type="checkbox"
              placeholder="consenso"
              {...register("consenso", { required: true })}
            />
            <CustomText type="paragrafo" className={classes.checkboxText}>
              Autorizzo Verteego al trattamento dei dati personali per ricevere
              comunicazioni.
            </CustomText>
          </div>
          <input
            type="submit"
            name="submit"
            className={classes.button}
            disabled={
              !dirtyFields.nome ||
              !dirtyFields.email ||
              !dirtyFields.numero ||
              !dirtyFields.consenso
            }
          />
        </form>
      )}
    </>
  );
};

export default Form;
