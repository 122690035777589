import React from "react";
import { SvgProps } from "../../Utils/svgUtils";

interface Props extends SvgProps {
  fill?: string;
}

const Instagram = (props: Props) => {
  const { fill = "#242422", ...restProps } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...restProps}>
      <path
        d="M12 8.25115C9.93559 8.25115 8.2509 9.93583 8.2509 12.0002C8.2509 14.0646 9.93559 15.7493 12 15.7493C14.0643 15.7493 15.749 14.0646 15.749 12.0002C15.749 9.93583 14.0643 8.25115 12 8.25115ZM23.2443 12.0002C23.2443 10.4477 23.2584 8.90927 23.1712 7.35958C23.084 5.55959 22.6734 3.96209 21.3571 2.64584C20.0381 1.32677 18.4434 0.918961 16.6434 0.831774C15.0909 0.744587 13.5525 0.758649 12.0028 0.758649C10.4503 0.758649 8.91184 0.744587 7.36215 0.831774C5.56215 0.918961 3.96465 1.32959 2.6484 2.64584C1.32934 3.9649 0.921525 5.55959 0.834337 7.35958C0.74715 8.91208 0.761213 10.4505 0.761213 12.0002C0.761213 13.5499 0.74715 15.0911 0.834337 16.6408C0.921525 18.4408 1.33215 20.0383 2.6484 21.3546C3.96746 22.6736 5.56215 23.0815 7.36215 23.1686C8.91465 23.2558 10.4531 23.2418 12.0028 23.2418C13.5553 23.2418 15.0937 23.2558 16.6434 23.1686C18.4434 23.0815 20.0409 22.6708 21.3571 21.3546C22.6762 20.0355 23.084 18.4408 23.1712 16.6408C23.2612 15.0911 23.2443 13.5527 23.2443 12.0002ZM12 17.7686C8.80777 17.7686 6.23152 15.1924 6.23152 12.0002C6.23152 8.80802 8.80777 6.23177 12 6.23177C15.1921 6.23177 17.7684 8.80802 17.7684 12.0002C17.7684 15.1924 15.1921 17.7686 12 17.7686ZM18.0046 7.34271C17.2593 7.34271 16.6575 6.74083 16.6575 5.99552C16.6575 5.25021 17.2593 4.64834 18.0046 4.64834C18.75 4.64834 19.3518 5.25021 19.3518 5.99552C19.3521 6.1725 19.3174 6.34778 19.2497 6.51133C19.1821 6.67488 19.0829 6.82348 18.9577 6.94862C18.8326 7.07376 18.684 7.17299 18.5205 7.24061C18.3569 7.30824 18.1816 7.34293 18.0046 7.34271Z"
        fill={fill}
      />
    </svg>
  );
};

export default Instagram;
