import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Link } from "gatsby-theme-material-ui";
import useFetchWorkItems from "../../hooks/useFetchWorkItems";
import "./work.css";
import CustomText from "../CustomText/CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  previewWorksRoot: ({ img }: any) => ({
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.colors.red,
    backgroundImage: `url(${img})`,
    transition: "background .4s ease-in",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    overflowX: "hidden",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      backgroundColor: theme.colors.black,
      width: "100%",
      height: "100%",
      opacity: 0.4,
    },
  }),
  sliderRoot: {
    width: "100%",
    position: "relative",
  },
  text: {
    fontSize: "clamp(32px, 6.250vw, 100px)",
    lineHeight: 1,
    letterSpacing: -2.5,
    color: theme.colors.black,
    textTransform: "uppercase",
    position: "relative",
    display: "inline-block",
    zIndex: 2,
  },
  backText: {
    position: "absolute",
    height: 34,
    width: "100%",
    top: "40%",
    background: theme.colors.red,
    transform: "translateY(-50%)",
  },
  hovered: {
    fontStyle: "italic",
    color: theme.colors.white,
    "&:before": {
      content: '""',
      display: "inline-block",
      height: 34,
      background:
        "linear-gradient(90deg, rgba(0,200,200,0) 0%, rgba(0,200,200,1) 10%, rgba(0,200,200,1) 90%, rgba(0,200,200,0) 100%)",
      width: "calc(100% + 240px)",
      position: "absolute",
      zIndex: -1,
      top: "40%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
  },
}));

const PreviewWorks = () => {
  const [img, setImg] = useState(null);
  const classes = useStyles({ img });
  const [hover, setHover] = useState(-1);
  const [autoHover, setAutoHover] = useState(0);
  const [stopAutop, setStopAutop] = useState(false);
  const { works } = useFetchWorkItems();
  const sortedWorks = works.sort((a, b) =>
    a.node.order > b.node.order ? 1 : -1
  );

  const handleMouseEnter = (idx) => {
    setHover(idx);
    setStopAutop(true);
  };

  const handleMouseLeave = () => {
    setStopAutop(false);
  };

  useEffect(() => {
    if (!stopAutop) {
      const timer = setTimeout(() => {
        setHover(autoHover);
        autoHover === 4 ? setAutoHover(0) : setAutoHover(autoHover + 1);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [autoHover, stopAutop]);

  useEffect(() => {
    const currWork = sortedWorks.find((_, idx) => idx === hover);
    const currImg = currWork
      ? currWork.node.coverImage?.file?.url
      : sortedWorks[0].node.coverImage?.file?.url;
    setImg(currImg);
  }, [hover]);

  return (
    <div className={classes.previewWorksRoot}>
      {sortedWorks.map((work, idx) => (
        <Link key={work.node.titoloProgetto} to={`works/${work.node.urlPath}`}>
          <div className={classes.sliderRoot}>
            {hover === idx && (
              <div
                className={clsx(classes.backText, "backtext")}
                style={{
                  backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='34px' width='300px'><text x='0' y='22' fill='white' style='font-size:18px; letter:-1; font-family:sans-serif' padding-left='20px'>${work.node.websiteobj.nome}</text></svg>")`,
                }}
              />
            )}
            <CustomText
              type="paragrafo"
              onMouseEnter={() => handleMouseEnter(idx)}
              onMouseLeave={handleMouseLeave}
              className={clsx(classes.text, {
                [classes.hovered]: hover === idx,
              })}
            >
              {work.node.titoloProgetto}
            </CustomText>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default PreviewWorks;
