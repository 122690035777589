import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import RotatingTextFooter from "../RotatingTextFooter";
import { Logo } from "../../images/Logo";
import { Link } from "gatsby";

const useStyles = makeStyles((theme: Theme) => ({
  collabotarionContent: {
    width: "100%",
    minHeight: 686,
    display: "flex",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    background: theme.colors.red,
    position: "relative",
    padding: "0 16px",
  },
  logo: {
    position: "absolute",
    justifyContent: "center",
    width: 240,
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: 172,
      height: 172,
    },
  },
}));

interface Props {}

const Collaboration = (props: Props) => {
  const classes = useStyles();
  return (
    <Link to="/contact-us" className={classes.collabotarionContent}>
      <RotatingTextFooter />
      <Logo className={classes.logo} />
    </Link>
  );
};

export default Collaboration;
