import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  heroVideo: {
    height: "100vh",
    width: "100%",
    objectFit: "cover",
    position: "sticky",
    top: 0,
    zIndex: 0,
  },
}));

const HeroVideo = () => {
  const classes = useStyles();
  return (
    <video
      src={`https://verteego.it/video/video-showreel-adv.mp4`}
      className={classes.heroVideo}
      autoPlay
      loop
      playsInline
      muted
      preload="auto"
    />
  );
};

export default HeroVideo;
