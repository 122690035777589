import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import MainAppbar from "../MainAppbar";
import "./worka.css";
import useFetchWorkItems from "../../hooks/useFetchWorkItems";
import { Link } from "gatsby-theme-material-ui";
import ArrowWorksNav from "../../images/ArrowWorksNav";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  next: {
    transform: "scaleX(-1)",
  },
}));

function loadScript(src, position) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.src = src;
  position.appendChild(script);
}

const PageWorks = () => {
  const { works } = useFetchWorkItems();
  const loaded = useRef(null);
  const classes = useStyles();

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#demo-1")) {
      loadScript(
        `https://verteego.it/js/imagesloaded.pkgd.min.js`,
        document.querySelector("body")
      );
      loadScript(
        `https://verteego.it/js/TweenMax.min.js`,
        document.querySelector("body")
      );
      loadScript(
        `https://verteego.it/js/demo1.js`,
        document.querySelector("body")
      );
    }

    loaded.current = true;
  }

  return (
    <>
      <Helmet>
        <body className="demo-1 loading" />
      </Helmet>
      <div>
        <MainAppbar position="absolute" />
        <div className="content">
          {works.map((work) => (
            <>
              <a
                key={work.node.coverImage?.file?.url}
                href={`works/${work.node.urlPath}`}
                className="content__slide content__slide"
              >
                <div className="content__img">
                  <div
                    className="content__img-inner"
                    style={{
                      backgroundImage: `url(${work.node.coverImage?.file?.url})`,
                    }}
                  ></div>
                </div>
                <div className="content__text-wrap">
                  <span className="content__text">
                    <span className="content__text-inner content__text-inner--stroke">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text">
                    <span className="content__text-inner">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text content__text--full">
                    <span className="content__text-inner content__text-inner--stroke">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text">
                    <span className="content__text-inner content__text-inner--stroke">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text">
                    <span className="content__text-inner">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text content__text--full">
                    <span className="content__text-inner">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text">
                    <span className="content__text-inner content__text-inner--bottom">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text">
                    <span className="content__text-inner">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text content__text--full">
                    <span className="content__text-inner content__text-inner--stroke">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text">
                    <span className="content__text-inner content__text-inner--stroke content__text-inner--bottom">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                  <span className="content__text">
                    <span className="content__text-inner">
                      {work.node.titoloProgetto}
                    </span>
                  </span>
                </div>
              </a>
            </>
          ))}

          <nav className="content__nav">
            <button className="content__nav-button content__nav-button--prev">
              <ArrowWorksNav />
            </button>
            <button className="content__nav-button content__nav-button--next">
              <ArrowWorksNav className={classes.next} />
            </button>
          </nav>
        </div>
      </div>
    </>
  );
};

export default PageWorks;
<Link
  style={{
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }}
/>;
