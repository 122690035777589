import React from "react";
import { SvgProps } from "../../Utils/svgUtils";

interface Props extends SvgProps {
  fill?: string;
}

export const Logo = (props: Props) => {
  const { fill = "#1D1D1B" } = props;
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" {...props}>
      <path
        d="M48.2981 12.4122C46.5344 10.3445 44.3429 8.68419 41.875 7.54589C39.4071 6.40759 36.7216 5.81843 34.0038 5.81909C26.0098 5.81909 19.1796 10.8022 16.4546 17.8273C15.9312 19.7832 15.5676 21.7785 15.3676 23.7933C15.3048 24.4623 15.249 25.1383 15.2211 25.8213C15.2002 26.3788 15.1863 26.9433 15.1863 27.5147V62.1808H37.2996V50.1795H47.1891V37.0974H52.8134V24.6297C52.8203 20.1478 51.2181 15.8124 48.2981 12.4122ZM46.702 26.8181C46.0822 27.7558 45.2523 28.536 44.2782 29.0968C43.3127 29.6606 42.2285 29.9903 41.1126 30.0595C39.9967 30.1287 38.8801 29.9355 37.8524 29.4953C37.5775 29.3835 37.3118 29.2503 37.0578 29.0968C35.9462 28.4546 35.0251 27.5286 34.3886 26.4137C35.0219 25.2937 35.9422 24.3627 37.0549 23.7166C37.3118 23.5686 37.5771 23.4356 37.8494 23.3181C38.8771 22.8779 39.9937 22.6847 41.1096 22.7539C42.2256 22.8231 43.3097 23.1528 44.2752 23.7166C45.2496 24.2801 46.0795 25.0628 46.699 26.0026C46.7897 26.1354 46.873 26.2742 46.9497 26.4137C46.876 26.5462 46.7927 26.6856 46.702 26.8181Z"
        fill={fill}
      />
      <path
        d="M46.9525 26.3326C46.9537 26.3815 46.9513 26.4305 46.9455 26.4791C46.9098 27.2051 46.6009 27.8907 46.0809 28.3986C45.5608 28.9064 44.868 29.1989 44.1414 29.2175C43.4147 29.236 42.7079 28.9792 42.1627 28.4985C41.6175 28.0178 41.2741 27.3487 41.2014 26.6255V26.5836C41.1944 26.5 41.1874 26.4176 41.1874 26.3326V26.2559C41.1866 26.2302 41.1889 26.2044 41.1944 26.1792C41.1944 26.1304 41.2014 26.0816 41.2014 26.0328C41.2737 25.3223 41.6077 24.6642 42.1385 24.1863C42.6692 23.7084 43.3586 23.445 44.0728 23.4473C44.5743 23.446 45.0671 23.5784 45.5005 23.8308C45.9188 24.0697 46.2702 24.41 46.5223 24.8204C46.7744 25.2309 46.9192 25.6982 46.9432 26.1792C46.9496 26.2301 46.9527 26.2814 46.9525 26.3326Z"
        fill={fill}
      />
    </svg>
  );
};
