import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import WorksLayout from "../../Components/WorksLayout";
import PageWorks from "../../Components/PageWorks";
import SinglePageWork from "../../Components/SinglePageWork";
import { Helmet } from "react-helmet";

const works = (props) => {
  const { pageContext } = props;
  if (typeof document === `undefined`) return null;

  return (
    <BrowserRouter>
      <Helmet>
        <title>Verteego Advertising – Lavori</title>
        <meta
          name="description"
          content="Una panoramica delle principali aziende che si sono affidate al team di Verteego Advertising per lo sviluppo dei loro progetti digitali."
        />
      </Helmet>
      <WorksLayout>
        <Routes>
          <Route path="/works" element={<PageWorks />} />
          <Route
            path={`/works/${pageContext?.work?.urlPath}`}
            element={<SinglePageWork {...pageContext.work} />}
          />
        </Routes>
      </WorksLayout>
    </BrowserRouter>
  );
};

export default works;
