import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainAppbar from "../MainAppbar";
import Seo from "../Seo";
import Hero from "../Hero";
import MainContent from "../MainContent";
import BackArea from "../BackArea";
import HeroVideo from "../HeroVideo";
import HeroContent from "../HeroContent";
import Footer from "../Footer/Footer";
import useIsMobile from "../../hooks/useIsMobile";

const useStyles = makeStyles(() => ({
  main: {
    justifyContent: "center",
  },
}));

export const App = () => {
  const classes = useStyles();
  const { isMobile } = useIsMobile();
  return (
    <React.Fragment>
      <Seo title="Verteego" />
      <main className={classes.main}>
        <Hero>
          <MainAppbar />
          <HeroContent />
          <div id="scroll-direction" style={{ height: "200vh" }}>
            <HeroVideo />
          </div>
        </Hero>
        <MainContent />
        <Footer />
        {!isMobile && <BackArea />}
      </main>
    </React.Fragment>
  );
};
