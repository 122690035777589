import React, { useCallback, useLayoutEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useIsMobile from "../../hooks/useIsMobile";
import ImageContainer from "./components/ImageContainer";
import { ButtonBase } from "@material-ui/core";
import ArrowWithTitle from "./components/ArrowWithTitle/ArrowWithTitle";
import CustomText from "../CustomText";
import useFetchProfiles from "../../hooks/useFetchProfiles";

const useStyles = makeStyles((theme: Theme) => ({
  thisIsUsRoot: {
    width: "100%",
    maxWidth: 1600,
    margin: "176px auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  title: {
    lineHeight: "54px",
  },
  titleMobile: {
    margin: "0px auto 24px auto",
    width: "max-content",
  },
  name: {
    fontSize: "clamp(28px, 3.625vw, 58px)",
    letterSpacing: -2.5,
    textTransform: "uppercase",
  },
  nameMobile: {
    lineHeight: "38px",
  },
  role: {
    lineHeight: "28px",
    opacity: 0,
    transition: "opacity 0.3s ease-in",
  },
  nameContainer: {
    margin: "24px 0px",
    width: "100%",
    display: "inline-grid",
    textAlign: "left",
    justifyContent: "left",
  },
  hovered: {
    opacity: 1,
    color: theme.colors.red,
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  thisIsUsRootMobile: {
    margin: "120px auto",
    display: "grid",
  },
  nameContainerMobile: {
    textAlign: "center",
    justifyContent: "center",
    width: "100%",
    margin: "8px auto",
  },
  roleMobile: {
    fontSize: "clamp(14px, 1.75vw, 22px)",
  },
  popper: {
    margin: "12px auto",
  },
}));

const AboutUsThisIsUsSection = () => {
  const classes = useStyles();
  const { isMobile, isMobileClasses } = useIsMobile();
  const [hovered, setHovered] = useState<number | null>(0);
  const { profiles } = useFetchProfiles();

  const getUrlPhoto = useCallback(
    (curr) => {
      return profiles.find((item) => item?.node?.file?.fileName?.includes(curr))
        ?.node?.file?.url;
    },
    [profiles]
  );

  const scrollSmooth = (element: HTMLElement) => {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleHovered = (idx: number | null) => {
    setHovered(idx);
  };

  const people = [
    {
      id: 1,
      name: "ANDREA BONELLI",
      role: "PROJECT MANAGER",
      img: getUrlPhoto("andrea"),
    },
    {
      id: 2,
      name: "fabrizio riccardi",
      role: "HEAD  OF COMMUNICATION AND MARKETING",
      img: getUrlPhoto("fabrizio"),
    },
    {
      id: 3,
      name: "giulio cafiero",
      role: "PROJECT MANAGER",
      img: getUrlPhoto("giulio"),
    },
    {
      id: 4,
      name: "luca turco",
      role: "ART DIRECTOR",
      img: getUrlPhoto("luca"),
    },
  ];

  const getImage = people.find((item) =>
    item.id === hovered ? item : null
  )?.img;

  useLayoutEffect(() => {
    if (isMobile && getImage) {
      scrollSmooth(document.getElementById("mobile_image"));
    }
  }, [isMobile, getImage]);

  return (
    <div
      className={isMobileClasses({
        webClass: classes.thisIsUsRoot,
        mobileClass: classes.thisIsUsRootMobile,
      })}
    >
      <div>
        <CustomText
          type="payoff"
          className={isMobileClasses({
            webClass: classes.title,
            mobileClass: classes.titleMobile,
          })}
        >
          THIS IS US
        </CustomText>
        {isMobile && <ArrowWithTitle />}
        {people.map((person, id) => {
          const idx = id + 1;
          return (
            <ButtonBase
              disableRipple
              disableTouchRipple
              component="div"
              key={person.id}
              className={isMobileClasses({
                webClass: classes.nameContainer,
                mobileClass: classes.nameContainerMobile,
              })}
              onMouseEnter={isMobile ? undefined : () => handleHovered(idx)}
              onMouseLeave={() => handleHovered(null)}
              onClick={isMobile ? () => handleHovered(idx) : () => {}}
            >
              <CustomText
                type="titoloH3People"
                className={isMobileClasses({
                  webClass: clsx(classes.name, {
                    [classes.hovered]: idx === hovered,
                  }),
                  mobileClass: classes.nameMobile,
                })}
              >
                {person.name}
              </CustomText>
              <CustomText
                type="paragrafo"
                className={isMobileClasses({
                  webClass: clsx(classes.role, {
                    [classes.hovered]: idx === hovered,
                  }),
                })}
              >
                {person.role}
              </CustomText>
            </ButtonBase>
          );
        })}
      </div>
      {!isMobile && <ImageContainer getImage={getImage} hovered={hovered} />}
      <div
        id="mobile_image"
        style={{
          width: "100%",
          display: isMobile && !!hovered && !!getImage ? "inherit" : "none",
        }}
      >
        <ImageContainer getImage={getImage} hovered={hovered} />
      </div>
    </div>
  );
};

export default AboutUsThisIsUsSection;
