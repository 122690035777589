import React from "react";
import { SvgProps } from "../../Utils/svgUtils";

interface Props extends SvgProps {
  fill?: string;
}

const ArrowWorksNav = (props: Props) => {
  const { fill = "#242422", ...rest } = props;
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" {...rest}>
      <rect width="90" height="90" rx="45" fill="white" fillOpacity="0.6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.3754 45C63.3754 44.6519 63.2371 44.3181 62.991 44.0719C62.7448 43.8258 62.411 43.6875 62.0629 43.6875H31.1063L39.3671 35.4293C39.4892 35.3072 39.586 35.1624 39.652 35.0029C39.7181 34.8435 39.7521 34.6726 39.7521 34.5C39.7521 34.3274 39.7181 34.1565 39.652 33.9971C39.586 33.8377 39.4892 33.6928 39.3671 33.5708C39.2451 33.4487 39.1002 33.3519 38.9408 33.2859C38.7814 33.2198 38.6105 33.1859 38.4379 33.1859C38.2653 33.1859 38.0944 33.2198 37.935 33.2859C37.7755 33.3519 37.6307 33.4487 37.5086 33.5708L27.0086 44.0708C26.8864 44.1927 26.7894 44.3375 26.7233 44.497C26.6571 44.6564 26.623 44.8274 26.623 45C26.623 45.1726 26.6571 45.3436 26.7233 45.503C26.7894 45.6625 26.8864 45.8073 27.0086 45.9293L37.5086 56.4293C37.6307 56.5513 37.7755 56.6481 37.935 56.7141C38.0944 56.7802 38.2653 56.8142 38.4379 56.8142C38.6105 56.8142 38.7814 56.7802 38.9408 56.7141C39.1002 56.6481 39.2451 56.5513 39.3671 56.4293C39.4892 56.3072 39.586 56.1624 39.652 56.0029C39.7181 55.8435 39.7521 55.6726 39.7521 55.5C39.7521 55.3274 39.7181 55.1565 39.652 54.9971C39.586 54.8377 39.4892 54.6928 39.3671 54.5708L31.1063 46.3125H62.0629C62.411 46.3125 62.7448 46.1742 62.991 45.9281C63.2371 45.6819 63.3754 45.3481 63.3754 45Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowWorksNav;
