import { graphql, useStaticQuery } from "gatsby";

const useFetchBlogItems = () => {
  const items = useStaticQuery(
    graphql`
      {
        allContentfulAdvArticoloBlog {
          edges {
            node {
              autore
              categoria
              id
              titolo
              contentful_id
              url
              createdAt(formatString: "DD MMMM YYYY")
              immagineCopertina {
                description
                title
                file {
                  contentType
                  fileName
                  url
                }
              }
              avatarAutore {
                file {
                  url
                  fileName
                  details {
                    image {
                      height
                      width
                    }
                    size
                  }
                }
                contentful_id
                title
              }
              articolo {
                raw
              }
            }
            previous {
              url
            }
            next {
              url
            }
          }
        }
      }
    `
  );

  return {
    blogItems: items.allContentfulAdvArticoloBlog.edges as IBlog[],
  };
};

export default useFetchBlogItems;

export interface IBlog {
  node: IBlogNode;
  previous: {
    url: string;
  };
  next: {
    url: string;
  };
}

export interface IBlogNode {
  autore: string;
  categoria: string;
  id: string;
  titolo: string;
  contentful_id: string;
  url: string;
  createdAt: string;
  immagineCopertina: ImmagineCopertinaType;
  avatarAutore: AvatarAutoreType;
  articolo: {
    raw: string;
  };
}

type AvatarAutoreType = {
  file: {
    url: string;
    fileName: string;
    details: {
      image: {
        height: string;
        width: string;
      };
      size: string;
    };
  };
  contentful_id: string;
  title: string;
};

type ImmagineCopertinaType = {
  description: string;
  title: string;
  file: {
    contentType: string;
    fileName: string;
    url: string;
  };
};
