import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useIsMobile from "../../hooks/useIsMobile";
import CustomText from "../CustomText/CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  aboutUsSubContent: {
    background: theme.colors.black,
    padding: "200px 0px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  titleSubContent: {
    lineHeight: "1",
    letterSpacing: -2,
    color: theme.colors.white,
  },
  belief: {
    position: "absolute",
    top: -30,
    padding: "8px 8px 4px",
    background: theme.colors.red,
    fontSize: "clamp(18px, 1.75vw, 28px)",
    lineHeight: "1",
    left: -100,
    textAlign: "center",
    transform: "rotate(-20deg)",
  },
  beliefDescription: {
    color: theme.colors.white,
    maxWidth: 710,
    marginLeft: 42,
  },
  titleSubContentTablet: {
    textAlign: "center",
    margin: "0 auto",
    alignContent: "center",
  },
  beliefTablet: {
    left: 30,
  },
  beliefDescriptionTablet: {
    textAlign: "center",
  },
  aboutUsSubContentMobile: {
    display: "grid",
    padding: "100px 0px",
  },
  belifMobile: {
    transform: "rotate(-9deg)",
  },
  titleSubContentMobile: {
    maxWidth: 300,
  },
  beliefDescriptionMobile: {
    margin: "0px auto",
    paddingTop: 24,
    textAlign: "center",
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const AboutUsBeliefSection = () => {
  const classes = useStyles();
  const { isMobileClasses } = useIsMobile();

  return (
    <div
      className={isMobileClasses({
        webClass: classes.aboutUsSubContent,
        tabletClass: classes.aboutUsSubContentMobile,
      })}
    >
      <div style={{ position: "relative", display: "inline-flex" }}>
        <CustomText
          type="titoloH4"
          className={isMobileClasses({
            webClass: classes.belief,
            tabletClass: classes.beliefTablet,
            mobileClass: classes.belifMobile,
          })}
        >
          OUR BELIEFS
        </CustomText>
        <CustomText
          type="titoloH2Sezioni"
          className={isMobileClasses({
            webClass: classes.titleSubContent,
            tabletClass: classes.titleSubContentTablet,
            mobileClass: classes.titleSubContentMobile,
          })}
        >
          VERTEEGO
          <br />
          WANTS TO
        </CustomText>
      </div>
      <CustomText
        type="paragrafo"
        className={isMobileClasses({
          webClass: classes.beliefDescription,
          tabletClass: classes.beliefDescriptionTablet,
          mobileClass: classes.beliefDescriptionMobile,
        })}
      >
        Offrire una visione d’insieme globale ed omogenea a ciascuno dei nostri
        partner e clienti, fornendo un supporto costante che si declina
        attraverso le sue tre aree commerciali: Entertainment, Advertising e
        Rent.
      </CustomText>
    </div>
  );
};

export default AboutUsBeliefSection;
