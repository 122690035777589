import React from "react";
import { ThemeProvider } from "../../Utils/themeProvider";
import MainAppbar from "../Components/MainAppbar";
import BackArea from "../Components/BackArea";
import useIsMobile from "../hooks/useIsMobile";
import ServicesListSection from "../Components/ServicesListSection";
import ServicesGalleryHeader from "../Components/ServicesGalleryHeader";
import Collaboration from "../Components/Collaboration";
import Footer from "../Components/Footer";
import Form from "../Components/Form";

import "../stylesheet.css";
import "../font.css";
import { Helmet } from "react-helmet";

export default function servicesPage() {
  const { isMobile } = useIsMobile();

  return (
    <ThemeProvider>
      <Helmet>
        <title>Verteego Advertising – Servizi</title>
        <meta
          name="description"
          content="Il Core Business di Verteego Advertising è la fornitura di servizi di Social Media Marketing, Paid Advertising, come Google Ads e Facebook Ads, e creazione di contenuti video, foto e grafici."
        />
      </Helmet>
      <MainAppbar currPage="services" />
      {!isMobile && <BackArea />}
      <ServicesGalleryHeader />
      <ServicesListSection />
      <Form />
      <Collaboration />
      <Footer />
    </ThemeProvider>
  );
}
