import React from "react";
import { SvgProps } from "../../Utils/svgUtils";

interface Props extends SvgProps {
  fill?: string;
}

const ArrowShort = (props: Props) => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" {...props}>
      <path
        d="M11.2883 15.8587L16.4496 10.6829L14.4168 8.65015L5.7666 17.3004L14.4168 25.9506L16.4496 23.9178L11.2883 18.7421H28.8338V15.8587H11.2883Z"
        fill="#242422"
      />
    </svg>
  );
};

export default ArrowShort;
