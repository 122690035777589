import React, { useState, createContext } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  Theme,
} from "@material-ui/core/styles";
import { theme } from "./theme";

export const ThemeContext = createContext(null);

interface Props {
  children: any;
}

export const ThemeProvider = ({ children }: Props) => {
  const [mode, setTheme] = useState<"dark" | "light">("light");
  return (
    <ThemeContext.Provider
      value={{
        mode,
        setTheme: () => setTheme(mode === "dark" ? "light" : "dark"),
      }}
    >
      <MuiThemeProvider theme={theme(mode) as Theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
