import { graphql, useStaticQuery } from "gatsby";

const useFetchSocial = () => {

  const items = useStaticQuery(
    graphql`
       {
        allContentfulSocial {
          edges {
            node {
              id
              linkAdv
              linkEnt
              nomeSocial
            }
          }
        }
      }
    `
  );

  return {
    social: items.allContentfulSocial.edges,
  };
};

export default useFetchSocial;
