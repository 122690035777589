import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { LogoOutline } from "../../images/LogoOutline";
import { homeLink } from "../../../Utils/costant";
import CustomText from "../CustomText/CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "fixed",
    top: "25%",
    left: 0,
    backgroundColor: "black",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "8px",
    paddingRight: "8px",
    display: "flex",
    writingMode: "vertical-rl",
    zIndex: 10,
  },
  text: {
    fontWeight: "bold",
    color: "white",
    fontSize: 16,
    marginTop: 16,
    transform: "rotate(180deg)",
    textDecoration: "underline",
  },
}));

const BackArea = () => {
  const classes = useStyles();
  return (
    <a href={homeLink} className={classes.container}>
      <LogoOutline />
      <CustomText type="paragrafo" className={classes.text}>
        LE NOSTRE AREE
      </CustomText>
    </a>
  );
};

export default BackArea;
