import React from "react";
import AboutUsLogoDescription from "../AboutUsLogoDescription";
import AboutUsBeliefSection from "../AboutUsBeliefSection";
import AboutUsThisIsUsSection from "../AboutUsThisIsUsSection";
import AboutUsEntertainment from "../AboutUsEntertainment";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    maxWidth: 1600,
    width: "100%",
    padding: "100px 0px",
    margin: "0px auto",
    [theme.breakpoints.down(1720)]: {
      padding: "100px clamp(16px, 8.281vw, 159px)",
    },
  },
}));

const AboutUsMainContent = () => {
  const classes = useStyles();
  return (
    <>
      <AboutUsLogoDescription />
      <AboutUsBeliefSection />
      <div className={classes.grid}>
        <AboutUsEntertainment />
        <AboutUsThisIsUsSection />
      </div>
    </>
  );
};

export default AboutUsMainContent;
