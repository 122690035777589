import React from "react";
import clsx from "clsx";
import { Typography, TypographyProps } from "@material-ui/core";
import textStyles from "../../hooks/textStyles";

type TextType =
  | "paragrafo"
  | "payoff"
  | "payoff2"
  | "titoloH1"
  | "titoloH2Services"
  | "titoloH2Sezioni"
  | "titoloH3People"
  | "titoloH3Sezioni"
  | "titoloH4";

interface Props extends TypographyProps {
  type?: TextType;
}

function CustomText(props: Props) {
  const { children, type = "paragrafo", className, ...restProps } = props;
  const {
    paragrafo,
    payoff,
    payoff2,
    titoloH1,
    titoloH2Services,
    titoloH2Sezioni,
    titoloH3People,
    titoloH3Sezioni,
    titoloH4,
  } = textStyles();
  const getType = () => {
    switch (type) {
      case "paragrafo":
        return paragrafo;
      case "payoff":
        return payoff;
      case "payoff2":
        return payoff2;
      case "titoloH1":
        return titoloH1;
      case "titoloH2Services":
        return titoloH2Services;
      case "titoloH2Sezioni":
        return titoloH2Sezioni;
      case "titoloH3People":
        return titoloH3People;
      case "titoloH3Sezioni":
        return titoloH3Sezioni;
      case "titoloH4":
        return titoloH4;
      default:
        return paragrafo;
    }
  };
  const { className: customClassName, ...rest } = getType();
  const getCustomClassName = () => clsx(customClassName, className);

  return (
    <Typography className={getCustomClassName()} {...rest} {...restProps}>
      {children}
    </Typography>
  );
}

export default CustomText;
