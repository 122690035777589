import React from "react";
import { ThemeProvider } from "../../Utils/themeProvider";
import { App } from "../Components/App";
import ScreenLoader from "../Components/ScreenLoader";
import "../stylesheet.css";
import "../font.css";
import "../index.css";
import { Helmet } from "react-helmet";

export default function IndexPage() {
  if (typeof document === `undefined`) {
    return <ScreenLoader />;
  } else {
    return (
      <ThemeProvider>
        <Helmet>
          <meta
            name="description"
            content="Verteego Advertising è il ramo dell’azienda che cura ogni aspetto relativo al Digital marketing, Web Development e Graphic Design; con uno sguardo attento anche alla produzione di spot e contenuti fotografici"
          />
        </Helmet>
        <App />
      </ThemeProvider>
    );
  }
}
