import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CustomText from "../CustomText/CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  video: {
    width: "100%",
    objectFit: "cover",
    position: "sticky",
    top: 0,
    zIndex: 0,
  },
  simpleHeader: {
    background: theme.colors.red,
    height: 430,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: theme.colors.white,
    margin: "0px auto",
  },
}));
const ServicesGalleryHeader = () => {
  const classes = useStyles();
  return (
    <section className={classes.simpleHeader}>
      <CustomText type="titoloH1" className={classes.title}>
        SERVIZI
      </CustomText>
    </section>
  );
};

export default ServicesGalleryHeader;

/* 
<section className="tiles tiles-rotated" id="galleryGrid">
<video
  src={`https://staging.verteego.it/video/showreel-verteego.webm`}
  className={classes.video}
  autoPlay
  loop
  playsInline
  muted
  preload="auto"
/> 
</section>
*/

/* <div className="tiles-wrap">
  <div className="tiles-line">
    <div className="tiles-line-img"></div>
    <div className="tiles-line-img"></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div className="tiles-line-img"></div>
  </div>

  <div className="tiles-line">
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
  </div>

  <div className="tiles-line">
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
  </div>

  <div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div
      className="tiles-line-img"
      style={{
        backgroundImage:
          "url(https://tympanus.net/Development/TileScroll/15.73adc2fc.jpg)",
      }}
    ></div>
    <div className="tiles-line-img"></div>
    <div className="tiles-line-img"></div>
    <div className="tiles-line-img"></div>
  </div>
</div> */
