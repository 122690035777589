import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Logo } from "../../images/Logo";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    height: 200,
    width: 200,
  },
  container: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ScreenLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Logo className={classes.logo} fill="black" />
    </div>
  );
};

export default ScreenLoader;
