import React from "react";
import { SvgProps } from "../../Utils/svgUtils";

interface Props extends SvgProps {
  fill?: string;
}

export const LogoFooter = (props: Props) => {
  const { fill = "white", ...restProps } = props;
  return (
    <svg
      width="235"
      height="57"
      viewBox="0 0 235 57"
      fill="none"
      {...restProps}
    >
      <path
        d="M52.528 14.981H57.3593L63.4547 37.2281H63.5315L69.628 14.981H74.4594L66.4851 41.9522H60.5034L52.528 14.981Z"
        fill={fill}
      />
      <path
        d="M76.6727 14.981H91.7045V18.8672H81.1203V26.2949H90.1692V29.7998H81.1203V38.0648H92.0866V41.9522H76.6727V14.981Z"
        fill={fill}
      />
      <path
        d="M94.8777 14.981H104.885C106.623 14.981 108.176 15.3239 109.544 16.0098C110.912 16.6957 111.973 17.6544 112.727 18.886C113.48 20.118 113.857 21.5337 113.857 23.1331C113.857 24.5809 113.429 25.946 112.573 27.2285C111.716 28.511 110.586 29.4189 109.181 29.952L114.356 41.9522H109.448L104.808 30.7905H99.4406V41.9522H94.8777V14.981ZM104.578 27.0952C105.933 27.0952 107.052 26.7142 107.934 25.9523C108.816 25.1904 109.255 24.2127 109.253 23.0191C109.253 21.699 108.819 20.6578 107.95 19.8955C107.081 19.1332 105.957 18.7525 104.578 18.7532H99.4785V27.0952H104.578Z"
        fill={fill}
      />
      <path
        d="M122.208 18.9812H115.381V14.981H133.632V18.9812H126.805V41.9522H122.203L122.208 18.9812Z"
        fill={fill}
      />
      <path
        d="M135.9 14.981H150.932V18.8672H140.348V26.2949H149.399V29.7998H140.348V38.0648H151.314V41.9522H135.9V14.981Z"
        fill={fill}
      />
      <path
        d="M154.104 14.981H169.136V18.8672H158.553V26.2949H167.602V29.7998H158.553V38.0648H169.519V41.9522H154.104V14.981Z"
        fill={fill}
      />
      <path
        d="M176.582 40.999C174.689 39.8563 173.245 38.2183 172.249 36.085C171.252 33.9517 170.754 31.4121 170.753 28.4664C170.753 25.5206 171.233 22.9746 172.191 20.8284C173.15 18.6821 174.524 17.0442 176.313 15.9144C178.102 14.7847 180.236 14.2196 182.716 14.2192C185.144 14.2192 187.266 14.9049 189.081 16.2764C190.897 17.6478 192.2 19.5396 192.992 21.9519L188.585 23.0947C188.073 21.5709 187.294 20.3773 186.246 19.514C185.198 18.6506 184.008 18.2189 182.676 18.2189C180.4 18.2189 178.617 19.1205 177.327 20.9236C176.036 22.7267 175.391 25.2282 175.391 28.4282C175.391 31.6533 176.094 34.1738 177.5 35.9898C178.906 37.8058 180.848 38.7137 183.328 38.7133C185.577 38.7133 187.366 37.9831 188.695 36.5228C190.025 35.0624 190.741 33.0372 190.843 30.4471H180.725V26.8664H195.372C195.398 27.1206 195.423 27.4246 195.449 27.7784C195.475 28.1322 195.488 28.5384 195.487 28.9971C195.487 31.8421 195.008 34.2931 194.049 36.3501C193.091 38.407 191.698 39.9815 189.87 41.0737C188.042 42.1654 185.863 42.7113 183.333 42.7113C180.723 42.7124 178.473 42.1416 176.582 40.999Z"
        fill={fill}
      />
      <path
        d="M203.51 40.942C201.656 39.7609 200.224 38.0975 199.215 35.9516C198.206 33.8058 197.701 31.3107 197.701 28.4664C197.701 25.6221 198.206 23.1268 199.215 20.9806C200.225 18.8343 201.656 17.1709 203.51 15.9902C205.363 14.8099 207.516 14.2196 209.97 14.2192C212.424 14.2189 214.578 14.8029 216.431 15.9714C218.284 17.1399 219.716 18.7969 220.726 20.9424C221.735 23.0879 222.24 25.5831 222.24 28.4282C222.24 31.2725 221.735 33.7738 220.726 35.9322C219.716 38.0906 218.284 39.7606 216.431 40.942C214.578 42.1226 212.424 42.713 209.97 42.713C207.517 42.713 205.363 42.1226 203.51 40.942ZM214.016 37.4752C215.179 36.6248 216.073 35.4249 216.7 33.8757C217.326 32.3264 217.639 30.5233 217.639 28.4664C217.639 26.4091 217.326 24.606 216.7 23.0571C216.074 21.5082 215.179 20.3144 214.016 19.4758C212.852 18.6379 211.504 18.2189 209.97 18.2189C208.462 18.2189 207.127 18.6379 205.963 19.4758C204.8 20.3137 203.906 21.5074 203.28 23.0571C202.653 24.6063 202.34 26.4094 202.34 28.4664C202.341 30.5233 202.654 32.3264 203.28 33.8757C203.906 35.4249 204.8 36.6248 205.963 37.4752C207.127 38.3257 208.462 38.7511 209.97 38.7515C211.504 38.7515 212.853 38.3261 214.016 37.4752Z"
        fill={fill}
      />
      <path
        d="M26.8696 5.86165C28.8315 5.86023 30.7745 6.24308 32.5873 6.98832C34.4002 7.73356 36.0474 8.82655 37.4347 10.2048C38.822 11.583 39.9222 13.2195 40.6724 15.0205C41.4226 16.8216 41.808 18.7519 41.8067 20.701V30.2622H37.0946V41.1543H28.8088V51.1413H11.9274V23.1036C11.9274 22.6892 11.9365 22.2383 11.9555 21.727C11.9796 21.1319 12.0318 20.5369 12.0748 20.0843C12.2372 18.4676 12.5297 16.8665 12.9498 15.2963C14.0459 12.5154 15.9607 10.1275 18.4439 8.4444C20.9272 6.7613 23.8635 5.86121 26.8696 5.86165ZM26.8696 5.0437C20.1719 5.0437 14.4501 9.19216 12.1666 15.0398C11.7281 16.6684 11.4235 18.3297 11.2561 20.0073C11.2033 20.5642 11.1563 21.1268 11.1333 21.6957C11.1161 22.1597 11.1041 22.6299 11.1041 23.1053V51.9587H29.6321V41.9689H37.9179V31.0819H42.6283V20.701C42.6283 16.5486 40.968 12.5663 38.0127 9.63003C35.0574 6.69374 31.0492 5.044 26.8696 5.0437Z"
        fill={fill}
      />
      <path
        d="M37.7187 22.1867C37.655 22.2967 37.585 22.4147 37.5087 22.5224C36.9893 23.3037 36.2934 23.9536 35.4766 24.4205C34.5608 24.9534 33.5183 25.2334 32.457 25.2316C31.6442 25.2359 30.8393 25.0719 30.0938 24.75C29.8632 24.6574 29.6401 24.5473 29.4266 24.4205C28.4951 23.8855 27.7231 23.1147 27.189 22.1867C27.7203 21.2535 28.4928 20.4782 29.4266 19.9409C29.6424 19.8182 29.8652 19.708 30.0938 19.6109C30.2373 19.5504 30.3807 19.4969 30.5316 19.4456C30.2614 19.7957 30.0951 20.2138 30.0514 20.6528C30.0514 20.6944 30.0468 20.7349 30.0468 20.7748C30.0418 20.7957 30.0397 20.8172 30.0405 20.8386V20.9036C30.0405 20.9732 30.0468 21.0427 30.0514 21.1122V21.1464C30.1134 21.7372 30.3937 22.2842 30.838 22.6816C31.2823 23.079 31.8591 23.2987 32.457 23.2982C33.0757 23.3002 33.6713 23.0648 34.1196 22.6412C34.5679 22.2176 34.8343 21.6384 34.8632 21.0245C34.868 20.9844 34.8699 20.944 34.869 20.9036C34.8698 20.8606 34.8679 20.8176 34.8632 20.7748C34.8399 20.2887 34.6664 19.8216 34.3664 19.437C34.7527 19.5653 35.1244 19.7334 35.4754 19.9386C36.2926 20.4076 36.9884 21.0594 37.5076 21.8424C37.584 21.9534 37.6545 22.0683 37.7187 22.1867Z"
        fill={fill}
      />
    </svg>
  );
};
