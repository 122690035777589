import React from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import logoHero from "../../images/logoHero.svg";
import CustomText from "../CustomText/CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  heroContent: {
    height: "calc(100vh - 112px)",
    width: "100%",
    position: "absolute",
    zIndex: 10,
  },
  title: {
    color: theme.palette.text.primary,
    textAlign: "center",
    paddingTop: 60,
    background: theme.colors.white,
    width: "100%",
  },
  logoContainer: {
    width: "100%",
  },
  logo: {
    backgroundImage: `url(${logoHero})`,
    backgroundSize: "cover",
    backgroundPositionX: "center",
    height: "100%",
    width: "100%",
    display: "flex",
    minHeight: 482,
    maxWidth: 580,
  },
  white: {
    background: theme.colors.white,
  },
  subtitle: {
    width: "100%",
    backgroundColor: theme.colors.white,
    color: theme.palette.text.primary,
    textAlign: "center",
  },
  border: {
    background: theme.colors.white,
    flexGrow: 1,
  },
  content: {
    display: "grid",
    alignContent: "center",
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: -5,
      bottom: -5,
      left: 0,
      right: 0,
      background: theme.colors.white,
      zIndex: -1,
    },
  },
}));

const HeroContent = () => {
  const classes = useStyles();

  return (
    <div className={classes.heroContent}>
      <Grid
        container
        style={{
          backgroundColor: "transparent",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} className={clsx(classes.white, classes.content)}>
          <CustomText type="titoloH1" className={classes.title}>
            VERTEEGO ADVERTISING
          </CustomText>
          <CustomText type="payoff2" className={classes.subtitle}>
            Makes your head spin
          </CustomText>
        </Grid>
        <Grid container>
          <Grid item className={classes.border} />
          <Grid item className={clsx("hero", classes.logo)} />
          <Grid item className={classes.border} />
        </Grid>
      </Grid>
    </div>
  );
};

export default HeroContent;
