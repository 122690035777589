import React from "react";
import clsx from "clsx";
import { IBlog } from "../../../../hooks/useFetchBlogItems";
import CustomText from "../../../CustomText";
import CategorieTitolo from "../CategorieTitolo";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Link } from "gatsby";
import { createLink } from "../../../../../Utils/costant";

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
  grid: {
    maxWidth: 1300,
  },
  item: {
    padding: "16px 36px",
    textTransform: "uppercase",
    border: `1px solid ${theme.colors.red}`,
    boxSizing: "border-box",
    width: "max-content",
    color: theme.colors.red,
    cursor: "pointer",
  },
  selected: {
    background: theme.colors.red,
    color: theme.colors.white,
  },
}));

type Props = {
  blogItems: IBlog[];
  selected: string;
};

const CategoriesSection = ({ blogItems, selected }: Props) => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <CategorieTitolo />
      <Grid
        container
        justifyContent="space-evenly"
        className={classes.grid}
        spacing={3}
      >
        {blogItems.map((blog) => {
          return (
            <Link
              key={blog.node.id}
              to={`/blog/${createLink(blog.node.categoria)}`}
              style={{ textDecoration: "none", margin: 16 }}
            >
              <Grid item>
                <CustomText
                  className={clsx(classes.item, {
                    [classes.selected]:
                      createLink(blog.node.categoria) === selected,
                  })}
                  type="titoloH4"
                >
                  {blog.node.categoria}
                </CustomText>
              </Grid>
            </Link>
          );
        })}
      </Grid>
    </section>
  );
};

export default CategoriesSection;
