import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useIsMobile from "../../hooks/useIsMobile";
import CustomText from "../CustomText";
import { graphql, useStaticQuery } from "gatsby";

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    maxWidth: 1600,
    width: "100%",
    margin: "180px auto",
    display: "flex",
    justifyContent: "space-between",
  },
  wonders: {
    lineHeight: "70px",
    letterSpacing: -2.5,
    textTransform: "uppercase",
    margin: "16px 0px",
  },
  description: {
    marginBottom: 43,
  },
  itemRoot: {
    display: "flex",
    paddingBottom: 40,
    paddingTop: 40,
    borderBottom: "2px solid #000000",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  number: {
    lineHeight: "42px",
    textTransform: "uppercase",
    minWidth: 101,
    marginRight: 34,
  },
  itemContent: {
    maxWidth: 667,
  },
  itemTitle: {
    lineHeight: "41.6px",
    textTransform: "uppercase",
  },
  itemSubtitle: {
    lineHeight: "27px",
  },
  img: {
    width: "50%",
    objectFit: "cover",
    boxSizing: "border-box",
    transform: "rotate(-4.46deg)",
    height: 600,
    paddingBottom: 40,
    position: "sticky",
    top: 24,
    paddingLeft: 50,
    paddingRight: 50,
  },
  contentContainerMobile: {
    margin: "150px auto",
  },
  ourMobile: {
    lineHeight: "24px",
  },
  descriptionMobile: {
    lineHeight: "24px",
  },
  imgMobile: {
    width: "100%",
  },
  imgContainerMobile: {
    width: "100%",
    justifyContent: "center",
    margin: "0px auto",
  },
  itemRootMobile: {
    display: "inherit",
  },
  itemTitleMobile: {
    lineHeight: "22px",
    margin: "16px 0px",
  },
  itemSubtitleMobile: {
    lineHeight: "24px",
  },
}));

const AboutUsEntertainmentContent = () => {
  const classes = useStyles();
  const { isMobileClasses, isTablet } = useIsMobile();
  const {
    allContentfulAsset: { edges },
  } = useStaticQuery(graphql`
    {
      allContentfulAsset(filter: { title: { regex: "/.*adv-chi-siamo.*/" } }) {
        edges {
          node {
            file {
              fileName
              url
              contentType
            }
            title
          }
        }
      }
    }
  `);

  const items = [
    {
      title: "SPOT PRODUCTION",
      subtitle: "Produzione pubblicitaria, produzione con VFX & 3D Elements",
    },
    {
      title: "DIGITAL MARKETING",
      subtitle:
        "Social media management, paid advertising, Facebook Ads, Google Ads, Data Analysis, Automation marketing, Influencer marketing",
    },
    {
      title: "WEB DEVELOPMENT",
      subtitle: "Sviluppo siti E-Commerce, Siti vetrina e app",
    },
    {
      title: "BRAND IDENTITY",
      subtitle: "Logo design, Graphic design e Packaging",
    },
    {
      title: "PHOTOGRAPHY",
      subtitle:
        "Food photography, fashion photography, product photography, still life, cataloghi.",
    },
  ];
  return (
    <div
      className={isMobileClasses({
        webClass: classes.contentContainer,
        mobileClass: classes.contentContainerMobile,
      })}
    >
      <div>
        <CustomText
          type="payoff"
          className={isMobileClasses({
            mobileClass: classes.ourMobile,
          })}
        >
          ADVERTISING
        </CustomText>
        <CustomText
          type="titoloH2Sezioni"
          className={isMobileClasses({
            webClass: classes.wonders,
          })}
        >
          STAND OUT AND SHINE
        </CustomText>
        <CustomText
          type="paragrafo"
          className={isMobileClasses({
            webClass: classes.description,
            mobileClass: classes.descriptionMobile,
          })}
        >
          Verteego Advertising si occupa di seguire e sviluppare la presenza
          delle aziende che si affidano ad essa, sia online che offline.
          L’obiettivo principale è quello di comprendere il mercato dei nostri
          clienti e interpretare tutte le loro esigenze primarie. <br /> <br />
          Solo così Verteego Advertising è in grado di sviluppare strategie
          comunicative e di marketing adeguate e specifiche. Grazie a
          consolidate conoscenze delle principali piattaforme di advertising
          digitale (Facebook Business Manager, Google Ads e Linkedin Ads) il
          nostro team digital è in grado di promuovere e comunicare online tutte
          le attività che si affidano a noi. <br /> <br />
          Dalla realizzazione del sito web fino ad arrivare alla promozione
          tramite strumenti di advertising e social media marketing, passando
          per la produzione di spot e shooting, siamo in grado di seguire ogni
          processo di realizzazione e promozione di un’attività. <br /> <br /> È
          fondamentale comprendere realmente la storia e la struttura
          organizzativa delle aziende con cui collaboriamo, in modo tale da
          esprimere al meglio vision e mission del nostro cliente.
        </CustomText>
        {isTablet && (
          <div className={classes.imgContainerMobile}>
            <img
              src={edges[0]?.node?.file?.url}
              alt={edges[0]?.node?.file?.fileName}
              className={classes.imgMobile}
            />
          </div>
        )}
        {items.map((item, idx) => (
          <div
            key={item.title}
            className={isMobileClasses({
              webClass: classes.itemRoot,
              mobileClass: classes.itemRootMobile,
            })}
          >
            <CustomText type="titoloH3Sezioni" className={classes.number}>
              {idx + 1 < 10 ? `0${idx + 1}.` : `${idx + 1}.`}
            </CustomText>
            <div className={classes.itemContent}>
              <CustomText
                type="titoloH3Sezioni"
                className={isMobileClasses({
                  webClass: classes.itemTitle,
                  mobileClass: classes.itemTitleMobile,
                })}
              >
                {item.title}
              </CustomText>
              <CustomText
                type="paragrafo"
                className={isMobileClasses({
                  webClass: classes.itemSubtitle,
                  mobileClass: classes.itemSubtitleMobile,
                })}
              >
                {item.subtitle}
              </CustomText>
            </div>
          </div>
        ))}
      </div>
      {!isTablet && (
        <img
          src={edges[0]?.node?.file?.url}
          alt={edges[0]?.node?.file?.fileName}
          className={classes.img}
        />
      )}
    </div>
  );
};

export default AboutUsEntertainmentContent;
