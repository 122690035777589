import { TypographyProps } from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ElementType } from "react";

interface Props extends TypographyProps {
  component?: ElementType;
}

const useStyles = makeStyles((theme: Theme) => ({
  titoloH1: theme.customText.titoloH1,
  payoff: theme.customText.payoff,
  h2Sezioni: theme.customText.h2Sezioni,
  h2Services: theme.customText.h2Services,
  h3People: theme.customText.h3People,
  h3Sezioni: theme.customText.h3Sezioni,
  titoloH4: theme.customText.titoloH4,
  paragrafo: theme.customText.paragrafo,
  payoff2: theme.customText.payoff2,
}));

const textStyles = () => {
  const classes = useStyles();

  const titoloH1: Props = {
    variant: "h1",
    className: classes.titoloH1,
  };
  const payoff: Props = {
    className: classes.payoff,
    component: "p",
  };
  const titoloH2Sezioni: Props = {
    variant: "h2",
    className: classes.h2Sezioni,
  };
  const titoloH2Services: Props = {
    variant: "h2",
    className: classes.h2Services,
  };
  const titoloH3People: Props = {
    variant: "h3",
    className: classes.h3People,
  };
  const titoloH3Sezioni: Props = {
    variant: "h3",
    className: classes.h3Sezioni,
  };
  const titoloH4: Props = {
    variant: "h4",
    className: classes.titoloH4,
  };
  const paragrafo: Props = {
    variant: "body1",
    className: classes.paragrafo,
    component: "p",
  };
  const payoff2: Props = {
    className: classes.payoff2,
    component: "p",
  };

  return {
    titoloH1,
    titoloH2Services,
    titoloH2Sezioni,
    payoff,
    titoloH3People,
    titoloH3Sezioni,
    titoloH4,
    paragrafo,
    payoff2,
  };
};

export default textStyles;
