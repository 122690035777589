import React, { useMemo } from "react";
import { Link } from "gatsby-theme-material-ui";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Divider } from "@material-ui/core";
import MainAppbar from "../MainAppbar";
import Collaboration from "../Collaboration";
import Footer from "../Footer";
import ArrowShort from "../../images/ArrowShort";
import useFetchBlogItems from "../../hooks/useFetchBlogItems";
import CustomText from "../CustomText/CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    background: theme.colors.white,
  },
  toolbar: {
    height: 112,
  },
  blogRoot: {
    maxWidth: 1050,
    width: "100%",
    paddingBottom: 170,
    margin: "0px auto",
    background: theme.colors.white,
    [theme.breakpoints.down("md")]: {
      padding: 24,
    },
  },
  title: {
    paddingTop: 170,
    letterSpacing: -2.5,
    paddingBottom: 24,
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
  autor: {
    paddingBottom: 24,
    "& span": {
      fontWeight: 800,
    },
  },
  article: {
    fontFamily: "Agrandir",
  },
  img: {
    width: "100%",
    marginBottom: 24,
  },
  divider: {
    marginTop: 80,
    background: theme.colors.black,
  },
  lastButton: {
    ...theme.customText.payoff,
    alignItems: "end",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  arrowRight: {
    transform: "rotate(180deg)",
  },
  arrowsContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "24px 0px",
  },
}));

const SinglePageBlog = ({ pageSelected }) => {
  const classes = useStyles();
  const { blogItems } = useFetchBlogItems();

  const currItem = useMemo(
    () => blogItems.find((item) => item.node.url === pageSelected.url),
    [blogItems, pageSelected]
  );

  const { id: blogId, titolo, articolo, autore, createdAt } = currItem.node;
  return (
    <div className={classes.background}>
      <MainAppbar position="absolute" />
      <div className={classes.blogRoot}>
        <div className={classes.toolbar} />
        <CustomText type="titoloH1" className={classes.title}>
          {titolo}
        </CustomText>
        <CustomText type="paragrafo" className={classes.autor}>
          By <span>{autore}</span> - {createdAt}
        </CustomText>
        <img
          className={classes.img}
          alt={blogId}
          src="https://picsum.photos/1080/900"
        />
        {articolo && articolo.raw && (
          <span className={classes.article}>
            {documentToReactComponents(JSON.parse(articolo.raw))}
          </span>
        )}
        <Divider className={classes.divider} />
        <div className={classes.arrowsContainer}>
          {currItem?.previous?.url ? (
            <Link to={`/blog/${currItem?.previous?.url}`}>
              <Button startIcon={<ArrowShort />} className={classes.lastButton}>
                articolo precedente
              </Button>
            </Link>
          ) : (
            <Button
              disabled
              startIcon={<ArrowShort />}
              className={classes.lastButton}
            >
              articolo precedente
            </Button>
          )}
          {currItem?.next?.url ? (
            <Link to={`/blog/${currItem?.next?.url}`}>
              <Button
                endIcon={<ArrowShort className={classes.arrowRight} />}
                className={classes.lastButton}
              >
                articolo successivo
              </Button>
            </Link>
          ) : (
            <Button
              disabled
              endIcon={<ArrowShort className={classes.arrowRight} />}
              className={classes.lastButton}
            >
              articolo successivo
            </Button>
          )}
        </div>
      </div>
      <Collaboration />
      <Footer />
    </div>
  );
};

export default SinglePageBlog;
