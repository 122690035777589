import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import MainAppbar from "../../../MainAppbar";
import CustomText from "../../../CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  video: {
    width: "100%",
    objectFit: "cover",
    position: "sticky",
    top: 0,
    zIndex: 0,
  },
  simpleHeader: {
    background: theme.colors.red,
    height: 430,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: theme.colors.white,
    margin: "0px auto",
  },
}));

const BlogsHeader = () => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>Verteego Advertising – Blog</title>
        <meta
          name="description"
          content="Il Core Business di Verteego Advertising è la fornitura di servizi di Social Media Marketing, Paid Advertising, come Google Ads e Facebook Ads, e creazione di contenuti video, foto e grafici."
        />
      </Helmet>
      <MainAppbar currPage="blogs" />
      <section className={classes.simpleHeader}>
        <CustomText type="titoloH1" className={classes.title}>
          BLOG
        </CustomText>
      </section>
    </>
  );
};

export default BlogsHeader;
