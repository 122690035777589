import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useFetchSocial from "../../hooks/useFetchSocial";
import Whatsapp from "../../images/Whatsapp";
import Instagram from "../../images/Instagram";
import LinkedIn from "../../images/LinkedIn";
import Facebook from "../../images/Facebook";
import clsx from "clsx";
import "./loopText.css";
import CustomText from "../CustomText";
import bgEmail from "../../images/adv-background-email.svg";
import useIsMobile from "../../hooks/useIsMobile";

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    maxWidth: "1600",
  },
  container: {
    paddingTop: 80,
    paddingBottom: 50,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.colors.red,
    height: "calc(100vh - 68px)",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    [theme.breakpoints.down(900)]: {
      paddingTop: 60,
      paddingBottom: 60,
    },
  },
  toolbar: {
    height: 68,
  },
  title: {
    textTransform: "uppercase",
    maxWidth: 690,
    letterSpacing: -2.5,
    textAlign: "center",
    margin: "0px auto",
  },
  phoneAndAddress: {
    display: "flex",
    width: "70%",
    justifyContent: "space-between",
  },
  box: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  social: {
    display: "flex",
    justifyContent: "space-between",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  icon: {
    width: 32,
    height: 32,
    margin: "0px 50px",
    [theme.breakpoints.down(900)]: {
      width: 32,
      height: 32,
      margin: "0px 24px",
    },
  },
  titleItem: {
    marginBottom: 8,
    fontSize: 22,
  },
  phoneTab: {
    width: "100%",
  },
  phoneMobile: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "24px",
  },
}));

const FirstQuote = () => {
  const classes = useStyles();
  const { social } = useFetchSocial();
  const { isMobileClasses } = useIsMobile();

  const getIcon = (value) => {
    switch (value) {
      case "whatsapp":
        return <Whatsapp className={classes.icon} />;
      case "instagram":
        return <Instagram className={classes.icon} />;
      case "linkedin":
        return <LinkedIn className={classes.icon} />;
      case "facebook":
        return <Facebook className={classes.icon} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={classes.toolbar} />
      <div className={clsx(classes.container, classes.grid)}>
        <CustomText type="titoloH1" className={classes.title}>
          Presentaci il tuo progetto
        </CustomText>
        <a
          id="marquee-section"
          style={{
            backgroundImage: `url(${bgEmail})`,
            backgroundColor: "black",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "center",
            transform: "rotate(-6deg)",
          }}
        />
        <div
          className={isMobileClasses({
            webClass: classes.phoneAndAddress,
            tabletClass: classes.phoneTab,
            mobileClass: classes.phoneMobile,
          })}
        >
          <div className={classes.box}>
            <CustomText type="titoloH3Sezioni" className={classes.titleItem}>
              Our Phone
            </CustomText>
            <a href="tel:+3908119006732" style={{ textDecoration: "none" }}>
              <CustomText type="paragrafo">+39 081 190 06 732</CustomText>
            </a>
          </div>
          <div className={classes.box}>
            <CustomText type="titoloH3Sezioni" className={classes.titleItem}>
              Our Address
            </CustomText>
            <CustomText type="paragrafo">
              Piazza Eritrea 3,
              <br /> 80122 Napoli (NA)
            </CustomText>
          </div>
        </div>
        <div className={classes.social}>
          {social.map((item) => (
            <a
              key={item.node.id}
              className={classes.link}
              href={item.node?.linkEnt}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getIcon(item.node.nomeSocial.toLowerCase())}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default FirstQuote;
