import React from "react";
import { Grid } from "@material-ui/core";
import { IBlog } from "../../../../hooks/useFetchBlogItems";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CustomText from "../../../CustomText";
import "./blogItem.css";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "170px auto",
    padding: "0px 16px",
  },
  grid: {
    maxWidth: 1600,
  },
  img: {
    width: "100%",
    objectFit: "cover",
  },
  item: {
    maxWidth: 470,
    width: "100%",
  },
  category: {
    margin: "16px 0px",
  },
  titolo: {
    webkitLineClamp: 2,
  },
}));

type Props = {
  blogItems: IBlog[];
};

const BlogGrid = ({ blogItems }: Props) => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <Grid
        container
        justifyContent="space-evenly"
        className={classes.grid}
        spacing={2}
      >
        {blogItems.map((blog) => {
          return (
            <Grid item key={blog.node.id} className={classes.item}>
              <img
                className={classes.img}
                alt={blog.node.immagineCopertina.file.fileName}
                src={blog.node.immagineCopertina.file.url}
              />
              <CustomText className={classes.category} type="paragrafo">
                {blog.node.categoria}
              </CustomText>
              <CustomText className="text" type="titoloH4">
                {blog.node.titolo}
              </CustomText>
            </Grid>
          );
        })}
      </Grid>
    </section>
  );
};

export default BlogGrid;
