import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "../../Utils/themeProvider";
import BackArea from "../Components/BackArea";
import ContactUsPage from "../Components/ContactUsPage";
import MainAppbar from "../Components/MainAppbar";
import useIsMobile from "../hooks/useIsMobile";

const contactUs = () => {
  const { isMobile } = useIsMobile();

  return (
    <ThemeProvider>
      <Helmet>
        <title>Verteego Advertising – Contatti</title>
        <meta
          name="description"
          content="Presentaci il tuo progetto: ci trovi su Instagram, Facebook e LinkedIn. Oppure scrivici su Whatsapp o all’indirizzo e-mail info@verteego.it"
        />
      </Helmet>
      <div>
        <MainAppbar currPage="contactUs" position="fixed" />
      </div>
      {!isMobile && <BackArea />}
      <ContactUsPage />
    </ThemeProvider>
  );
};

export default contactUs;
