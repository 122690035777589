import React from "react";
import OurServicesTitle from "../OurServicesTitle";
import Collaboration from "../Collaboration";
import OurServicesDescription from "../OurServicesDescription";
import PreviewWorks from "../PreviewWorks";
// import BlogCarousel from "../BlogCarousel";
import OurWorkTitle from "../OurWorkTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  temp: {
    height: 160,
    [theme.breakpoints.down("md")]: {
      height: 48,
    },
  },
}));

const MainContent = () => {
  const classes = useStyles();
  return (
    <div data-section-scroll>
      <OurWorkTitle />
      <PreviewWorks />
      <OurServicesTitle />
      <OurServicesDescription />
      {/*  <BlogCarousel /> */}
      <div className={classes.temp} />
      <Collaboration />
    </div>
  );
};

export default MainContent;
