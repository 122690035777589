import React from "react";
import { SvgProps } from "../../Utils/svgUtils";

interface Props extends SvgProps {
  fill?: string;
}

const Whatsapp = (props: Props) => {
  const { fill = "#242422", ...restProps } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...restProps}>
      <path
        d="M21.792 5.8078C18.336 0.479804 11.28 -1.1042 5.80805 2.2078C0.480049 5.5198 -1.24795 12.7198 2.20805 18.0478L2.49605 18.4798L1.34405 22.7998L5.66405 21.6478L6.09605 21.9358C7.96805 22.9438 9.98405 23.5198 12 23.5198C14.16 23.5198 16.32 22.9438 18.192 21.7918C23.52 18.3358 25.104 11.2798 21.792 5.8078ZM18.768 16.8958C18.192 17.7598 17.472 18.3358 16.464 18.4798C15.888 18.4798 15.168 18.7678 12.288 17.6158C9.84005 16.4638 7.82405 14.5918 6.38405 12.4318C5.52005 11.4238 5.08805 10.1278 4.94405 8.8318C4.94405 7.6798 5.37605 6.6718 6.09605 5.9518C6.38405 5.6638 6.67205 5.5198 6.96005 5.5198H7.68005C7.96805 5.5198 8.25605 5.5198 8.40005 6.0958C8.68805 6.8158 9.40805 8.5438 9.40805 8.6878C9.55205 8.8318 9.55205 9.11981 9.40805 9.2638C9.55205 9.5518 9.40805 9.8398 9.26405 9.9838C9.12005 10.1278 8.97605 10.4158 8.83205 10.5598C8.54405 10.7038 8.40005 10.9918 8.54405 11.2798C9.12005 12.1438 9.84005 13.0078 10.56 13.7278C11.424 14.4478 12.288 15.0238 13.296 15.4558C13.584 15.5998 13.872 15.5998 14.016 15.3118C14.16 15.0238 14.88 14.3038 15.168 14.0158C15.456 13.7278 15.6 13.7278 15.888 13.8718L18.192 15.0238C18.48 15.1678 18.768 15.3118 18.912 15.4558C19.056 15.8878 19.056 16.4638 18.768 16.8958Z"
        fill={fill}
      />
    </svg>
  );
};

export default Whatsapp;
