import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "../../Utils/themeProvider";
import BackArea from "../Components/BackArea";
import Footer from "../Components/Footer";
import MainAppbar from "../Components/MainAppbar";
import PrivacyCookiePolicy from "../Components/PrivacyCookiePolicy";

const privacyCookiePolicy = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ThemeProvider>
      <Helmet>
        <title>Verteego Entertainment – Privacy & Cookie Policy</title>
      </Helmet>
      <div>
        <MainAppbar />
      </div>
      {!isMobile && <BackArea />}
      <PrivacyCookiePolicy />
      <Footer />
    </ThemeProvider>
  );
};

export default privacyCookiePolicy;
