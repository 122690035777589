import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

const Player = (props: ReactPlayerProps) => {
  return (
    <div style={{ position: "relative", paddingTop: "56.25%" }}>
      <ReactPlayer
        width="100%"
        height="100%"
        style={{ position: "absolute", top: 0, left: 0 }}
        {...props}
      />
    </div>
  );
};

export default Player;
