import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Footer from "../Footer";
import Collaboration from "../Collaboration";
import MainAppbar from "../MainAppbar";
import useIsMobile from "../../hooks/useIsMobile";
import { IWorkNode } from "../../hooks/useFetchWorkItems";
import CustomText from "../CustomText/CustomText";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Player from "../Player/Player";
import { VimeoConfig } from "react-player/vimeo";

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    height: 100,
  },
  workContent: {
    maxWidth: 1600,
    width: "100%",
    padding: "100px 0px",
    margin: "0px auto",
    [theme.breakpoints.down(1720)]: {
      padding: "clamp(50px, 4vw, 100px) clamp(16px, 4.4vw, 160px)",
    },
  },
  logo: {
    maxWidth: "clamp(142px, 14.500vw, 182px)",
    marginBottom: 32,
  },
  article: {
    ...theme.customText.paragrafo,
    "& p": {
      marginRight: 32,
      maxWidth: 920,
      [theme.breakpoints.down(600)]: {
        marginRight: 0,
      },
    },
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  titleList: {
    fontWeight: 800,
    color: theme.colors.red,
    margin: "24px 0px",
    maxWidth: 204,
  },
  listItem: {
    marginBottom: 24,
    fontSize: "clamp(18px, 1.75vw, 28px)",
    textDecoration: "none",
    ...theme.customText.titoloH4,
  },
  grid: {
    display: "grid",
  },
  paragrafoSpan: {
    ...theme.customText.paragrafo,
  },
}));

const SinglePageWork = (props: IWorkNode) => {
  const {
    coverImage,
    descrizioneCorta,
    descrizioneLunga,
    gallery,
    logo,
    servizi,
    titoloProgetto,
    urlvideo,
    urlVideo2,
    websiteobj,
  } = props;
  const { isMobileClasses, isTablet } = useIsMobile();

  const classes = useStyles();
  const vimeoOptions: VimeoConfig["playerOptions"] = {
    autoplay: true,
    loop: true,
    playsinline: true,
    muted: true,
    autopause: false,
  };

  const vimeoOptionsControls: VimeoConfig["playerOptions"] = {
    ...vimeoOptions,
    controls: true,
  };

  console.log("urlVideo2", urlVideo2);
  return (
    <>
      <MainAppbar currPage="works" position="absolute" />
      <div style={{ overflow: "auto", background: "white" }}>
        <div className={classes.toolbar} />
        <img
          src={coverImage.file.url}
          alt={coverImage.file.fileName}
          style={{ width: "100%" }}
        />
        <section
          className={isMobileClasses({
            webClass: clsx(classes.workContent, classes.flex),
            tabletClass: classes.grid,
          })}
        >
          <div>
            {isTablet && (
              <img
                src={logo.file.url}
                alt={logo.file.fileName}
                className={classes.logo}
              />
            )}
            <CustomText type="titoloH1">{titoloProgetto}</CustomText>
            {descrizioneCorta && descrizioneCorta.raw && (
              <span className={classes.article}>
                {documentToReactComponents(JSON.parse(descrizioneCorta.raw))}
              </span>
            )}
          </div>
          <div>
            {!isTablet && (
              <img
                src={logo.file.url}
                alt={logo.file.fileName}
                className={classes.logo}
              />
            )}
            <CustomText type="titoloH3Sezioni" className={classes.titleList}>
              SERVICES
            </CustomText>
            {servizi.map((servizio, idx) => (
              <CustomText
                key={idx}
                type="titoloH4"
                className={classes.listItem}
              >
                {servizio.toUpperCase()}
              </CustomText>
            ))}
            <CustomText type="titoloH3Sezioni" className={classes.titleList}>
              WEBSITE
            </CustomText>
            <a
              target="_blank"
              href={websiteobj.url}
              className={classes.listItem}
              rel="noreferrer"
            >
              {websiteobj.nome.toUpperCase()}
            </a>
          </div>
        </section>
        {urlvideo && (
          <section className={classes.workContent}>
            <Player
              config={{
                vimeo: { playerOptions: vimeoOptions },
              }}
              url={urlvideo}
            />
          </section>
        )}
        <section className={classes.workContent}>
          {descrizioneLunga && descrizioneLunga.raw && (
            <span className={classes.paragrafoSpan}>
              {documentToReactComponents(JSON.parse(descrizioneLunga.raw))}
            </span>
          )}
        </section>
        {gallery && (
          <section className={classes.workContent}>
            <>
              {urlVideo2 && (
                <Player
                  config={{
                    vimeo: { playerOptions: vimeoOptions },
                  }}
                  url={urlVideo2}
                />
              )}
              {gallery.map((media) => {
                const {
                  file: { fileName, url, contentType },
                } = media;
                const isImage = contentType.includes("image");

                return (
                  <div key={fileName} style={{ padding: "24px 0px" }}>
                    {isImage ? (
                      <img alt={fileName} src={url} style={{ width: "100%" }} />
                    ) : (
                      <Player
                        config={{
                          vimeo: { playerOptions: vimeoOptionsControls },
                        }}
                        url={url}
                      />
                    )}
                  </div>
                );
              })}
            </>
          </section>
        )}
      </div>
      <Collaboration />
      <Footer />
    </>
  );
};

export default SinglePageWork;
