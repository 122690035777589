import React from "react";
import { SvgProps } from "../../Utils/svgUtils";

const ArrowDown = (props: SvgProps) => {
  return (
    <svg width="140" height="160" viewBox="0 0 140 160" fill="none" {...props}>
      <path
        d="M9.44972 84.4023L64.4023 137.23L64.4023 -3.30448e-06L76.5465 -2.77364e-06L76.5465 137.23L131.803 84.4023L140 93.2068L70.4744 160L0.948787 93.2068L9.44972 84.4023Z"
        fill="black"
      />
    </svg>
  );
};

export default ArrowDown;
