import React from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import StyledButton from "../StyledButton/StyledButton";
import { Link } from "gatsby-theme-material-ui";
import CustomText from "../CustomText/CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  servicesRootContainer: {
    maxWidth: 1600,
    width: "100%",
    margin: "0px auto",
    display: "flex",
    marginTop: 208,
    height: "100%",
    padding: "100px 0px",
    [theme.breakpoints.down(1720)]: {
      padding: "100px clamp(16px, 8.281vw, 159px)",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 110,
      flexDirection: "column",
    },
  },
  firstContainer: {
    width: "50%",
    marginRight: "clamp(32px, 17.625vw, 282px)",
    [theme.breakpoints.down("md")]: {
      padding: "0px 24px",
      marginRight: 0,
      width: "unset",
    },
  },
  secondContainer: {
    width: "50%",
    display: "grid",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "50px 0px",
    },
  },
  subAdvertising: {
    lineHeight: "54,6px",
    marginBottom: 16,
  },
  title: {
    letterSpacing: -2.5,
    textTransform: "uppercase",
    maxWidth: 537,
    width: "100%",
  },
  mb: {
    marginBottom: 32,
  },
  number: {
    color: theme.colors.white,
    backgroundColor: theme.colors.black,
    borderRadius: "50%",
    width: 100,
    height: 100,
    minWidth: 100,
    marginRight: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "65px",
    fontSize: 28,
    [theme.breakpoints.down("md")]: {
      marginRight: 24,
      width: 70,
      height: 70,
      minWidth: 70,
      fontSize: 24,
    },
  },
  serviceListItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "0px 24px",
      marginBottom: 50,
    },
  },
  customSize: {
    fontSize: "clamp(18px, 1.75vw, 28px)",
  },
  button: {
    width: 268,
    height: 250,
    "&:before": {
      background: "black !important",
    },
    "&:after": {
      border: `1px solid ${theme.colors.red} !important`,
    },
    "& span": {
      fontFamily: "Agrandir",
      fontSize: 12,
      FontWeight: "bold",
    },
  },
}));

const OurServicesDescription = () => {
  const classes = useStyles();
  const services = [
    "SPOT PRODUCTION",
    "DIGITAL MARKETING",
    "WEB DEVELOPMENT",
    "GRAPHIC DESIGN",
    "PHOTOGRAPHY",
  ];
  return (
    <div className={classes.servicesRootContainer}>
      <div className={classes.firstContainer}>
        <CustomText type="payoff" className={classes.subAdvertising}>
          ADVERTISING
        </CustomText>
        <CustomText
          type="titoloH2Sezioni"
          className={clsx(classes.title, classes.mb)}
        >
          STAND Out and shine
        </CustomText>
        <CustomText type="paragrafo" className={clsx(classes.text, classes.mb)}>
          Verteego Advertising si occupa di seguire e sviluppare la presenza
          delle aziende che si affidano ad essa, sia online che offline.
        </CustomText>
        <CustomText type="paragrafo" className={clsx(classes.text, classes.mb)}>
          L’obiettivo principale è quello di comprendere il mercato dei nostri
          clienti e interpretare tutte le loro esigenze primarie. Solo così
          Verteego Advertising è in grado di sviluppare strategie comunicative e
          di marketing adeguate e specifiche. Grazie a consolidate conoscenze
          delle principali piattaforme di advertising digitale (Facebook
          Business Manager, Google Ads e Linkedin Ads) il nostro team digital è
          in grado di promuovere e comunicare online tutte le attività che si
          affidano a noi.
        </CustomText>
        <CustomText type="paragrafo" className={clsx(classes.text, classes.mb)}>
          Dalla realizzazione del sito web fino ad arrivare alla promozione
          tramite strumenti di advertising e social media marketing, passando
          per la produzione di spot e shooting, siamo in grado di seguire ogni
          processo di realizzazione e promozione di un’attività. È fondamentale
          comprendere realmente la storia e la struttura organizzativa delle
          aziende con cui collaboriamo, in modo tale da esprimere al meglio
          vision e mission del nostro cliente.
        </CustomText>
        <Link href="/services" className={classes.mb}>
          <StyledButton
            label="VIEW OUR SERVICES"
            button="janus"
            className={classes.button}
          />
        </Link>
      </div>
      <div className={classes.secondContainer}>
        {services.map((serv, idx) => (
          <div key={serv} className={classes.serviceListItem}>
            <CustomText type="titoloH4" className={classes.number}>
              {idx + 1 < 10 ? `0${idx + 1}.` : `${idx + 1}.`}
            </CustomText>
            <CustomText type="titoloH4" className={clsx(classes.customSize)}>
              {serv}
            </CustomText>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServicesDescription;
