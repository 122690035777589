import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CustomText from "../../../CustomText";

const useStyles = makeStyles((theme: Theme) => ({
  categoriesRoot: {
    display: "flex",
    position: "relative",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 121,
    [theme.breakpoints.down("md")]: {
      marginTop: 42,
    },
  },
  categoriesBack: {
    maxWidth: 1320,
    width: "100%",
    color: theme.colors.red,
    opacity: 0.1,
    fontWeight: 900,
    fontSize: "clamp(48px, 5.813vw, 93px)",
  },
  categoriesFront: {
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const CategorieTitolo = () => {
  const classes = useStyles();
  return (
    <div className={classes.categoriesRoot}>
      <CustomText className={classes.categoriesBack}>CATEGORIE</CustomText>
      <CustomText type="titoloH3People" className={classes.categoriesFront}>
        CATEGORIE
      </CustomText>
    </div>
  );
};

export default CategorieTitolo;
