import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 1200,
    width: "100%",
    padding: "24px",
    margin: "0 auto",
  },
}));

const PrivacyCookiePolicy = () => {
  const classes = useStyles();
  const data = useStaticQuery(
    graphql`
      query {
        iubendaDocument {
          privacyPolicy
        }
      }
    `
  );
  return (
    <div className={classes.container}>
      <div
        dangerouslySetInnerHTML={{ __html: data.iubendaDocument.privacyPolicy }}
      />
    </div>
  );
};
export default PrivacyCookiePolicy;
