import React from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { LogoFooter } from "../../images/LogoFooter";
import useFetchSocial from "../../hooks/useFetchSocial";
import CustomText from "../CustomText";
import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme: Theme) => ({
  footerRoot: {
    background: theme.colors.black,
  },
  footerContainer: {
    display: "grid",
    width: "100%",
    maxWidth: 1600,
    gridTemplateColumns: "3fr 3fr 2fr 2fr 2fr",
    columnGap: "50px",
    padding: "100px 0px",
    margin: "0px auto",
    [theme.breakpoints.down(1720)]: {
      padding: "100px clamp(16px, 8.281vw, 160px)",
    },
    [theme.breakpoints.down(1270)]: {
      gridTemplateColumns: "1fr 1fr 1fr",
      rowGap: 100,
    },
    [theme.breakpoints.down(768)]: {
      gridTemplateColumns: "1fr",
      rowGap: 50,
    },
  },
  googlePartnerImg: {
    margin: "0 auto",
    [theme.breakpoints.down(1270)]: {
      gridColumnStart: 2,
      gridColumnEnd: 4,
    },
    [theme.breakpoints.down(768)]: {
      gridColumnStart: 1,
      gridColumnEnd: 1,
      margin: 0,
    },
  },
  textTitle: {
    color: theme.colors.white,
    fontSize: 40,
  },
  text: {
    color: theme.colors.white,
    fontSize: 18,
    textTransform: "none",
  },
  column: {
    width: 214,
    [theme.breakpoints.down(768)]: {
      width: "auto",
    },
  },
  marginBottom24: {
    marginBottom: theme.spacing(3),
  },
  marginBottom16: {
    marginBottom: theme.spacing(2),
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { social } = useFetchSocial();

  const officeItem = [
    {
      link: "https://www.google.it/maps/place/Piazza+Eritrea,+3,+80122+Napoli+NA,+Italia/@40.8318633,14.2198047,17z/data=!3m1!4b1!4m5!3m4!1s0x133b091954aa4c75:0x6690151c9c7f08ec!8m2!3d40.8318633!4d14.2219934",
      label: "PIAZZA ERITREA, 3 80122 - NAPOLI (NA) - IT",
    },
    {
      link: "tel:+3908119006732",
      label: "+39 081 1900 6732",
    },
    {
      link: "mailto:verteegosrls@pec.it",
      label: "verteegosrls@pec.it",
    },
    {
      link: "mailto:info@verteego.it",
      label: "info@verteego.it",
    },
  ];

  const entertainmentItem = [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "Servizi",
      link: "/services",
    },
    {
      label: "Lavori",
      link: "/works",
    },
    {
      label: "Chi Siamo",
      link: "/about-us",
    },
    {
      label: "Contatti",
      link: "/contact-us",
    },
    {
      label: "Privacy e Cookie Policy",
      link: "/privacy-cookie-policy",
    },
  ];

  const columns = [
    {
      title: "Office",
      items: officeItem,
    },
    {
      title: "Advertising",
      items: entertainmentItem,
    },
    {
      title: "Social",
      items: social,
    },
  ];
  return (
    <footer className={classes.footerRoot}>
      <div className={classes.footerContainer}>
        <div>
          <LogoFooter className={classes.marginBottom24} />
          <CustomText className={classes.text}>
            VERTEEGO S.R.L. SEMPLIFICATA <br />
            P.IVA: IT09480201210 <br />
            C.F.: 09480201210 <br />
            PIAZZA ERITREA, 3 80122 <br />
            NAPOLI (NA) - IT
            <br />
            <br />
            <br />© Verteego SRLS. All Right Reserved.
          </CustomText>
        </div>

        <StaticImage
          alt="verteego-partener-google"
          src="../../images/google-Partner-RGB.png"
          className={classes.googlePartnerImg}
          layout="fixed"
          width={120}
        />

        {columns.map((column) => (
          <div key={column.title} className={classes.column}>
            <CustomText
              type="titoloH4"
              className={clsx(classes.text, classes.marginBottom24)}
            >
              {column.title}
            </CustomText>
            {column.items?.map((item) => (
              <CustomText
                key={item.node?.id || item.link}
                className={clsx(classes.text, classes.marginBottom16)}
              >
                <a
                  className={classes.link}
                  href={item.node?.linkAdv || item.link}
                  target={
                    item.node?.linkAdv ||
                    item.link?.includes("mailto") ||
                    item.link?.includes("google")
                      ? "_blank"
                      : undefined
                  }
                  rel="noopener noreferrer"
                >
                  {item?.node?.nomeSocial || item.label}
                </a>
              </CustomText>
            ))}
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
