import { graphql, useStaticQuery } from "gatsby";

const useFetchProfiles = () => {
  const items = useStaticQuery(
    graphql`
      {
        allContentfulAsset(
          filter: { file: { fileName: { regex: "/.*profilo.*/" } } }
        ) {
          edges {
            node {
              file {
                fileName
                url
                contentType
              }
            }
          }
        }
      }
    `
  );

  return {
    profiles: items.allContentfulAsset.edges,
  };
};

export default useFetchProfiles;
