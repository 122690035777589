import React, { useMemo } from "react";
import clsx from "clsx";
import "./buttonStyles.css";

interface Props {
  label: string;
  button: buttonType;
  className?: string;
}

type buttonType =
  | "pan"
  | "hyperion"
  | "mimas"
  | "atlas"
  | "kari"
  | "pandora"
  | "janus"
  | "anthe"
  | "pallene"
  | "telesto"
  | "calypso"
  | "skoll"
  | "greip"
  | "dione"
  | "helene"
  | "rhea"
  | "narvi"
  | "hati"
  | "bestia";

/** For 'hati' effect need a backgroud-image inside before css style */

const StyledButton = (props: Props) => {
  const { label = "", button = "janus", className } = props;

  const addAnimation = useMemo(
    () => button === "atlas" || button === "kari",
    [button]
  );

  return (
    <button className={clsx(`button button--${button}`, className)}>
      {button === "bestia" ? (
        <>
          <div className="button__bg"></div>
          <span>Locate</span>
        </>
      ) : (
        <span>{label}</span>
      )}
      {addAnimation && (
        <div className="marquee" aria-hidden="true">
          <div className="marquee__inner">
            <span>{label}</span>
            <span>{label}</span>
            <span>{label}</span>
            <span>{label}</span>
          </div>
        </div>
      )}
    </button>
  );
};

export default StyledButton;
