import React from "react";
import { createLink } from "../../../Utils/costant";
import { ThemeProvider } from "../../../Utils/themeProvider";
import useFetchBlogItems from "../../hooks/useFetchBlogItems";
import Collaboration from "../Collaboration";
import Footer from "../Footer";
import BlogGrid from "./components/BlogGrid";
import BlogsHeader from "./components/BlogsHeader";
import CategoriesSection from "./components/CategoriesSection";

const PageBlogs = ({ categoria }: { categoria?: string }) => {
  const { blogItems } = useFetchBlogItems();

  const filteredItems = blogItems.filter((item) => {
    return categoria ? createLink(item.node.categoria) === categoria : item;
  });

  return (
    <ThemeProvider>
      <BlogsHeader />
      <CategoriesSection selected={categoria} blogItems={blogItems} />
      <BlogGrid blogItems={filteredItems} />
      <Collaboration />
      <Footer />
    </ThemeProvider>
  );
};

export default PageBlogs;
