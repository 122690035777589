import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import useIsMobile from "../../hooks/useIsMobile";
import CustomText from "../CustomText/CustomText";
import useFetchServices from "../../hooks/useFetchServices";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    maxWidth: 1600,
    width: "100%",
    padding: "0 16px",
    margin: "180px auto",
  },
  servicesWrapper: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    paddingBottom: 100,
    paddingTop: 100,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  servicesNumber: {
    fontSize: 150,
    lineHeight: 1,
    textTransform: "uppercase",
    color: theme.colors.red,
  },
  itemTitle: {
    marginBottom: 16,
    textTransform: "uppercase",
  },
  contentContainerMobile: {
    margin: "150px auto",
  },
  servicesWrapperMobile: {
    display: "inherit",
  },
  itemTitleMobile: {
    margin: "16px 0px",
  },
  itemSubtitleMobile: {
    lineHeight: "24px",
  },
}));

const ServicesListSection = () => {
  const classes = useStyles();
  const { isMobileClasses } = useIsMobile();
  const { services } = useFetchServices();

  return (
    <div
      className={isMobileClasses({
        webClass: classes.contentContainer,
        mobileClass: classes.contentContainerMobile,
      })}
      id="servicesList"
    >
      {services.map((item, idx) => (
        <div
          key={item.title}
          className={isMobileClasses({
            webClass: clsx("border-top", classes.servicesWrapper),
            mobileClass: classes.servicesWrapperMobile,
          })}
        >
          <CustomText type="paragrafo" className={classes.servicesNumber}>
            {idx + 1 < 10 ? `0${idx + 1}.` : `${idx + 1}.`}
          </CustomText>

          <div>
            <CustomText
              type="titoloH2Services"
              className={isMobileClasses({
                webClass: classes.itemTitle,
                mobileClass: classes.itemTitleMobile,
              })}
            >
              {item?.node?.titolo}
            </CustomText>
            <CustomText
              type="paragrafo"
              className={isMobileClasses({
                mobileClass: classes.itemSubtitleMobile,
              })}
            >
              {documentToReactComponents(
                JSON.parse(item?.node?.descrizione?.raw)
              )}
            </CustomText>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesListSection;
